import { FormBuilder, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { DoubleNumberEditorConfigurationModel } from './models/double-number-editor-configuration.model';
import { DoubleNumberValueModel } from './models/double-number-value.model';

@Component({
  selector: 'ca-double-number-editor',
  templateUrl: './double-number-editor.component.html',
  styleUrls: ['./double-number-editor.component.scss'],
})
export class DoubleNumberEditorComponent extends FilterPanelEditorComponent implements OnInit {
  private _value: DoubleNumberValueModel = {
    leftValue: 0,
    rightValue: 0,
  };

  actualConfig: DoubleNumberEditorConfigurationModel;

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as DoubleNumberEditorConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  get showValidationMessage(): boolean {
    return (
      this.actualConfig.showValidationMessage != null && this.actualConfig.showValidationMessage
    );
  }

  init(): void {
    super.init();
  }

  autoSelectFirstItem(): void {}

  reload(): void {}

  reset(): void {
    const editorLeftControl = this.editorForm.get('editorLeftValue');
    const editorRightControl = this.editorForm.get('editorRightValue');
    if (editorLeftControl) {
      editorLeftControl.setValue(null, { emitEvent: true });
    }
    if (editorRightControl) {
      editorRightControl.setValue(null, { emitEvent: true });
    }
  }

  onValueChange(eventArgs) {
    this.onChange();
  }

  set value(value: any) {
    this._value = value;

    this.editorForm.patchValue({
      editorLeftValue: value.editorLeftValue,
      editorRightValue: value.editorRightValue,
    });
  }

  get value(): any {
    return {
      editorLeftValue: this.editorForm.controls.editorLeftValue.value,
      editorRightValue: this.editorForm.controls.editorRightValue.value,
    };
  }

  onLeftValueChange(value: number): void {
    if (value) {
      const editorLeftControl = this.editorForm.get('editorLeftValue');

      if (this.actualConfig.minValue != null && value < this.actualConfig.minValue) {
        value = this.actualConfig.minValue;
        if (editorLeftControl) {
          editorLeftControl.setValue(value, { emitEvent: true });
        }
        this.editorForm.patchValue({ editorLeftValue: value });
      }

      if (this.actualConfig.maxValue != null && value > this.actualConfig.maxValue) {
        value = this.actualConfig.maxValue;
        if (editorLeftControl) {
          editorLeftControl.setValue(value, { emitEvent: true });
        }
        this.editorForm.patchValue({ editorLeftValue: value });
      }

      const maxValue = this.editorForm.get('editorRightValue')?.value;

      if (!isNaN(parseInt(maxValue)) && value > maxValue) {
        if (editorLeftControl) {
          editorLeftControl.setValue(maxValue, { emitEvent: true });
        }
        this.editorForm.patchValue({ editorLeftValue: maxValue });
      }
    }
  }

  onRightValueChange(value: number): void {
    if (value) {
      const editorRightControl = this.editorForm.get('editorRightValue');

      if (this.actualConfig.minValue != null && value < this.actualConfig.minValue) {
        value = this.actualConfig.minValue;
        if (editorRightControl) {
          editorRightControl.setValue(value, { emitEvent: true });
        }
        this.editorForm.patchValue({ editorRightValue: value });
      }

      if (this.actualConfig.maxValue != null && value > this.actualConfig.maxValue) {
        value = this.actualConfig.maxValue;
        if (editorRightControl) {
          editorRightControl.setValue(value, { emitEvent: true });
        }
        this.editorForm.patchValue({ editorRightValue: value });
      }

      const editorLeftControl = this.editorForm.get('editorLeftValue');
      const minValue = editorLeftControl?.value;

      if (!isNaN(parseInt(minValue)) && minValue > value) {
        if (editorLeftControl) {
          editorLeftControl.setValue(value, { emitEvent: true });
        }
        this.editorForm.patchValue({ editorLeftValue: value });
      }
    }
  }

  constructor(protected fb: FormBuilder) {
    super(fb);

    this.editorForm.addControl('editorLeftValue', new FormControl());
    this.editorForm.addControl('editorRightValue', new FormControl());

    this.editorForm.valueChanges.subscribe(val => {
      this._value.leftValue = val.editorLeftValue;
      this._value.rightValue = val.editorRightValue;
      this.onChange();
    });
  }

  ngOnInit(): void {}
}
