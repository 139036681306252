import { coreOptionsFactory } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export interface ComponentCanDeactivate {
  canDeactivate(): boolean | Observable<boolean>;
}

export const CanDeactivateState = {
  defendAgainstBrowserBackButton: false,
  hasLogoutBeenInitiated: false
};

@Injectable()
export class CanDeactivateGuard  {
  constructor(private confirmationService: ConfirmationService) {}

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    const canDeactivate = component.canDeactivate && component.canDeactivate();
    if (canDeactivate) {
      return true;
    }

    const options: Partial<Confirmation.Options> = {
      hideCancelBtn: false,
      hideYesBtn: false,
      dismissible: false,
      cancelText: '::UnsavedChangesNavigationConfirmationStay',
      yesText: '::UnsavedChangesNavigationConfirmationLeave',
      messageLocalizationParams: [],
      titleLocalizationParams: [],
    };

    const isConfirmed = this.confirmationService
      .warn(
        '::UnsavedChangesNavigationConfirmationMessage',
        '::UnsavedChangesNavigationConfirmationTitle',
        options
      )
      .pipe(
        tap((status: Confirmation.Status) => {
          if (
            status !== Confirmation.Status.confirm &&
            CanDeactivateState.defendAgainstBrowserBackButton
          ) {
            history.pushState(null, '', '');
          }
        }),
        map(status => status === Confirmation.Status.confirm)
      );

    return isConfirmed;
  }
}
