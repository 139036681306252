import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { SimpleTermWithRangeAndOccurenceQueryItemDto } from 'src/core/models/query/simple-term-with-range-and-occurence-query-item.dto';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { map } from 'rxjs/operators';
import { QueryRangeUnit } from 'src/core/models/generic-lookup-type/query/query-range-unit.glt';
import { ConversationSide } from 'src/core/models/generic-lookup-type/conversation/conversation-side.glt';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QueryRangeValidator } from '../../../../core/validators/query/query-range.validator';
import { IntegerValidator } from '../../../../core/validators/shared/integer.validator';
import { SimpleTermValidator } from 'src/core/validators/query/simple-term.validator';
import { Operators } from 'src/core/models/request/operator.enum';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'ca-query-tree-node-simple-term-with-range-and-occurence',
  templateUrl: './query-tree-node-simple-term-with-range-and-occurence.component.html',
  styleUrls: ['./query-tree-node-simple-term-with-range-and-occurence.component.scss'],
})
export class QueryTreeNodeSimpleTermWithRangeAndOccurenceComponent
  extends QueryEditorBase
  implements OnInit
{
  payload: SimpleTermWithRangeAndOccurenceQueryItemDto;
  conversationSides$: Observable<GenericLookupDto[]>;
  rangeUnits$: Observable<GenericLookupDto[]>;
  occurenceHidden = false;
  queryForm: FormGroup = null;
  min = 0;
  max: number;
  currentConversationSide: string;
  currentUnitName: string;
  termInternal: string;
  conversationSideAny: number = ConversationSide.any;

  onNotChange(eventArgs: MatSlideToggleChange) {
    this.occurenceHidden = eventArgs.checked;
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private fb: FormBuilder,
    private store: Store,
    private operators: Operators
  ) {
    super();

    this.rangeUnits$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(QueryRangeUnit)));

    this.conversationSides$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ConversationSide)));

    this.queryForm = fb.group(
      {
        term: [
          null,
          {
            validators: [Validators.required, SimpleTermValidator.Validator],
          },
        ],
        conversationSide: [null],
        startTime: [null],
        endTime: [null],
        rangeUnit: [null],
        occurrence: [
          null,
          {
            validators: [Validators.required, IntegerValidator.minMax(this.min, this.max)],
          },
        ],
      },
      { validator: [QueryRangeValidator.TimeRangeValidator], updateOn: 'blur' }
    );

    this.queryForm.statusChanges.subscribe(status => {
      this.node.validationStatus = status === 'VALID';
    });

    this.queryForm.get('term').valueChanges.subscribe(value => {
      this.queryForm.updateValueAndValidity();

      if (this.queryForm.valid) {
        const newValue = this.queryForm.get('term').value;
        this.payload.operator = newValue.endsWith('*') ? operators.Contains : operators.Equals;
        this.payload.term = newValue.endsWith('*')
          ? newValue.substring(0, newValue.length - 1)
          : newValue.trim();
      }
    });
  }

  ngOnInit() {
    this.payload = this.node.categoryItem.payload as SimpleTermWithRangeAndOccurenceQueryItemDto;
    this.termInternal =
      this.payload.operator === this.operators.Contains
        ? this.payload.term + '*'
        : this.payload.term;

    this.conversationSides$.subscribe((sides: GenericLookupDto[]) => {
      this.currentConversationSide = sides.find(x => x.id == this.payload.sideId).name;
    });

    this.rangeUnits$.subscribe((rangeUnits: GenericLookupDto[]) => {
      this.currentUnitName = rangeUnits.find(x => x.id == this.payload.rangeUnitId).name;
    });
  }
}
