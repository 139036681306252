<div
  [hidden]="!_collapsed"
  class="bg-light h-100 text-center c-pointer pt-2"
  [style.width]="collapsedWidth + 'px'"
  (click)="onExpandClick($event)">
  <i class="fad fa-filter d-inline" style="font-size: 1.5em"></i>
  <span class="badge rounded-pill bg-primary text-light position-relative filter-collapse-style">
    {{ totalCount }}
  </span>
</div>
<div
  [hidden]="_collapsed"
  class="main-container"
  style="display: flex; flex-direction: column; justify-content: stretch; height: 100%"
  (keyup.enter)="onEnterKeyPressed($event)">
  <div *ngIf="!config.hideHeader" style="flex: 0 0 25px">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="title">
        <i class="bi-filter-circle title-icon"></i>
        <span class="title-text ps-1 pe-1">{{ 'CA::ShowFilters' | abpLocalization }}</span>
        <div class="icon-container">
          <i
            placement="bottom"
            ngbTooltip="{{ 'CA::CollapseAll' | abpLocalization }}"
            container="body"
            class="far fa-compress-arrows-alt"
            (click)="accordion.collapseAll()"
            *ngIf="config.buttons.collapseAll"></i>
          <i
            placement="bottom"
            ngbTooltip="{{ 'CA::ExpandAll' | abpLocalization }}"
            container="body"
            class="far fa-expand-arrows"
            (click)="accordion.expandAll()"
            *ngIf="config.buttons.expandAll"></i>
          <i
            placement="bottom"
            ngbTooltip="{{ 'CA::Minimize' | abpLocalization }}"
            container="body"
            class="far fa-chevron-double-left"
            (click)="onCollapseClick()"
            *ngIf="config.collapsible"></i>
        </div>
      </div>
    </nav>
  </div>
  <div style="flex: 1; overflow-y: auto">
    <div
      [id]="'filter-panel-item-' + item.key"
      [ngClass]="getCardClass(item)"
      *ngFor="let item of stickyItems; let i = index">
      <div>
        <ca-filter-panel-item-factory
          *ngFor="let editor of item.items"
          [editorConfig]="editor"
          [itemConfig]="item"
          [filterPanelConfig]="config"
          [parent]="thisRef"></ca-filter-panel-item-factory>
      </div>
    </div>
    <div
      ngbAccordion
      #accordion="ngbAccordion"
      class="pt-3"
      [destroyOnHide]="false"
      [closeOthers]="config.closeOthers">
      <div
        ngbAccordionItem
        #accordionItem="ngbAccordionItem"
        [id]="'filter-panel-item-' + item.key"
        [cardClass]="getCardClass(item)"
        *ngFor="let item of nonStickyItems; let i = index">
        <div ngbAccordionHeader>
          <div
            ngbAccordionToggle
            *ngIf="item.getHiddenValue != null ? !item.getHiddenValue(this.values) : true"
            class="d-flex justify-content-between align-items-center c-pointer"
            (click)="accordion.toggle('filter-panel-item-' + item.key)">
            <div class="d-flex justify-content-between align-items-center truncate">
              <h5 class="m-0 filter-panel-item-title">
                {{ item.title | abpLocalization }}
              </h5>
              <div
                *ngIf="!opened && item.getBadgeText(this.values).visible"
                class="badge accordion-badge bg-light"
                container="body"
                [ngbTooltip]="item.getBadgeText(this.values).text"
                showTooltipIfTruncated>
                <span class="badge-text">{{ item.getBadgeText(this.values).text }}</span>
              </div>
            </div>
            <button style="margin-left: auto" class="btn btn-link p-0 me-2">
              <i [ngClass]="!accordionItem.collapsed ? 'fas fa-angle-up' : 'fas fa-angle-down'"></i>
            </button>
          </div>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ca-filter-panel-item-factory
              *ngFor="let editor of item.items"
              [editorConfig]="editor"
              [itemConfig]="item"
              [filterPanelConfig]="config"
              [parent]="thisRef"></ca-filter-panel-item-factory>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="!(!config.buttons.clear && !config.buttons.filter)"
    style="
      flex: 0 0 18px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      justify-items: stretch;
      padding-top: 8px;
      padding-bottom: 16px;
      padding-left: 8px;
      padding-right: 8px;
    ">
    <button
      *ngIf="config.buttons.clear"
      class="btn btn-sm ca-btn ca-btn-neutral"
      style="flex: 1"
      (click)="onClearFilters()">
      {{ 'CA::Clear' | abpLocalization }}
    </button>
    <div style="flex: 0 0 16px"></div>
    <button
      *ngIf="config.buttons.filter"
      class="btn btn-sm ca-btn ca-btn-primary"
      style="flex: 1"
      (click)="onSubmitFilters($event, accordion)"
      [disabled]="!isValid">
      <i class="far fa-filter"></i>
      <span>{{ 'CA::Filter' | abpLocalization }}</span>
    </button>
  </div>
</div>
