import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDividerModule } from '@angular/material/divider';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserPhotoModule } from 'src/ca-shared/user-photo/user-photo.module';
import { CoreModule } from 'src/core/core.module';
import { ConversationCategoryPanelModule } from '../conversation-category-panel/conversation-category-panel.module';
import { CallSummaryComponent } from './call-summary.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ConversationSentimentLabelModule } from '../conversation-sentiment-label/conversation-sentiment-label.modul';
import { ConversationIconContainerModule } from 'src/ca-shared/conversation-icon-container/conversation-icon-container.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [CallSummaryComponent],
  imports: [
    CommonModule,
    SharedModule,
    ConversationCategoryPanelModule,
    UserPhotoModule,
    MatDividerModule,
    ClipboardModule,
    MatTooltipModule,
    CoreModule,
    NgxSkeletonLoaderModule,
    NgbModule,
    NgbTooltipModule,
    ConversationSentimentLabelModule,
    ConversationIconContainerModule,
  ],
  exports: [CallSummaryComponent],
})
export class CallSummaryModule {}
