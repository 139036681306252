import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReasonTableSelectorComponent } from './components/reason-table-selector/reason-table-selector.component';
import { CoreModule } from 'src/core/core.module';
import { ReasonDropdownSelectorComponent } from './components/reason-dropdown-selector/reason-dropdown-selector.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

export { ReasonTableSelectorComponent } from './components/reason-table-selector/reason-table-selector.component';
export { ReasonDropdownSelectorComponent } from './components/reason-dropdown-selector/reason-dropdown-selector.component';

@NgModule({
  declarations: [ReasonTableSelectorComponent, ReasonDropdownSelectorComponent],
  imports: [
    CoreModule,
    CommonModule,
    SharedModule,
    MatTableModule,
    MatTooltipModule,
    NgSelectModule,
    NgbModule,
  ],
  exports: [ReasonTableSelectorComponent, ReasonDropdownSelectorComponent],
})
export class ReasonSelectorModule {}
