import { UserSelectionPanelComponent } from './../children/user-selection/user-selection-panel/user-selection-panel.component';
import { QuerySelectionPanelComponent } from './../children/query-selection/query-selection-panel/query-selection-panel.component';
import { TextEditorComponent } from './../children/text/text-editor/text-editor.component';
import { GenericLookupSelectionPanelComponent } from './../children/generic-lookup-selection/generic-lookup-selection-panel/generic-lookup-selection-panel.component';
import { FilterPanelConfigurationModel } from './../models/filter-panel-configuration.model';
import { FilterPanelItemConfigurationModel } from './../models/filter-panel-item-configuration.model';
import { FilterPanelEditorConfigurationModel } from './../models/filter-panel-editor-configuration.model';
import { FilterPanelComponent } from './../filter-panel/filter-panel.component';
import { DateSelectionPanelComponent } from './../children/date-selection/date-selection-panel/date-selection-panel.component';
import { Editor } from 'src/ca-shared/filter-panel/models/editor.enum';
import { FilterPanelEditorComponent } from '../models/filter-panel-editor.component';
import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { CategorySelectionPanelComponent } from '../children/category-selection/category-selection-panel/category-selection-panel.component';
import { DepartmentSelectionPanelComponent } from '../children/department-selection/department-selection-panel/department-selection-panel.component';
import { RoleSelectionPanelComponent } from '../children/role-selection/role-selection-panel/role-selection-panel.component';
import { ConversationSideSelectionPanelComponent } from '../children/conversation-side-selection/conversation-side-selection-panel/conversation-side-selection-panel.component';
import { AssignmentSelectionPanelComponent } from '../children/assignment-selection/assignment-selection-panel/assignment-selection-panel.component';
import { FormSelectionPanelComponent } from '../children/form-selection/form-selection-panel/form-selection-panel.component';
import { TopicSelectionPanelComponent } from '../children/topic-selection/topic-selection-panel/topic-selection-panel.component';
import { AutomaticAssignmentSelectionPanelComponent } from '../children/automatic-assignment-selection/automatic-assignment-selection-panel/automatic-assignment-selection-panel.component';
import { TagSelectionPanelComponent } from '../children/tag-selection/tag-selection-panel/tag-selection-panel.component';
import { AttachedDataSelectionPanelComponent } from '../children/attached-data-selection/attached-data-panel/attached-data-selection-panel.component';
import { NumberEditorComponent } from '../children/number-editor/number-editor.component';
import { QuickSearchWithSideSelectionPanelComponent } from '../children/quick-search-with-side-selection-panel/quick-search-with-side-selection-panel.component';
import { BooleanSelectionPanelComponent } from '../children/boolean-selection/boolean-selection-panel/boolean-selection-panel.component';
import { NumberEditorWithOperatorComponent } from '../children/number-editor-with-operator/number-editor-with-operator.component';
import { ScenarioSelectionPanelComponent } from '../children/scenario-selection/scenario-selection-panel/scenario-selection-panel.component';
import { FormVersionSelectionPanelComponent } from '../children/form-version-selection/form-version-selection-panel/form-version-selection-panel.component';
import { QuestionSelectionPanelComponent } from '../children/question-selection/question-selection-panel/question-selection-panel.component';
import { FormSectionSelectionPanelComponent } from '../children/form-section-selection/form-section-selection-panel/form-section-selection-panel.component';
import { LastLoginDateSelectionPanelComponent } from '../children/last-login-date-selection/last-login-date-selection-panel/last-login-date-selection-panel.component';
import { AttachedDataMultipleSelectionPanelComponent } from '../children/attached-data-selection/attached-data-multiple-selection-panel/attached-data-multiple-selection-panel.component';
import { DoubleNumberEditorComponent } from '../children/double-number-editor/double-number-editor.component';

@Component({
  selector: 'ca-filter-panel-item-factory',
  templateUrl: './filter-panel-editor-factory.component.html',
  styleUrls: ['./filter-panel-editor-factory.component.scss'],
})
export class FilterPanelEditorFactoryComponent implements OnInit {
  @Input()
  editorConfig: FilterPanelEditorConfigurationModel;

  @Input()
  itemConfig: FilterPanelItemConfigurationModel;

  @Input()
  filterPanelConfig: FilterPanelConfigurationModel;

  @Input()
  parent: FilterPanelComponent;

  private mappings: { [key: number]: any } = {};

  constructor(private viewContainerRef: ViewContainerRef) {
    this.mappings[Editor.Date] = DateSelectionPanelComponent;
    this.mappings[Editor.GenericLookup] = GenericLookupSelectionPanelComponent;
    this.mappings[Editor.Text] = TextEditorComponent;
    this.mappings[Editor.Query] = QuerySelectionPanelComponent;
    this.mappings[Editor.Assignment] = AssignmentSelectionPanelComponent;
    this.mappings[Editor.AutomaticAssignment] = AutomaticAssignmentSelectionPanelComponent;
    this.mappings[Editor.Form] = FormSelectionPanelComponent;
    this.mappings[Editor.Category] = CategorySelectionPanelComponent;
    this.mappings[Editor.Department] = DepartmentSelectionPanelComponent;
    this.mappings[Editor.User] = UserSelectionPanelComponent;
    this.mappings[Editor.Role] = RoleSelectionPanelComponent;
    this.mappings[Editor.ConversationSide] = ConversationSideSelectionPanelComponent;
    this.mappings[Editor.Topic] = TopicSelectionPanelComponent;
    this.mappings[Editor.Tag] = TagSelectionPanelComponent;
    this.mappings[Editor.AttachedData] = AttachedDataSelectionPanelComponent;
    this.mappings[Editor.AttachedDataMultiple] = AttachedDataMultipleSelectionPanelComponent;
    this.mappings[Editor.Number] = NumberEditorComponent;
    this.mappings[Editor.QuickSearchWithConversationSide] =
      QuickSearchWithSideSelectionPanelComponent;
    this.mappings[Editor.BooleanSelection] = BooleanSelectionPanelComponent;
    this.mappings[Editor.NumberWithOperator] = NumberEditorWithOperatorComponent;
    this.mappings[Editor.Scenario] = ScenarioSelectionPanelComponent;
    this.mappings[Editor.FormVersion] = FormVersionSelectionPanelComponent;
    this.mappings[Editor.Question] = QuestionSelectionPanelComponent;
    this.mappings[Editor.Section] = FormSectionSelectionPanelComponent;
    this.mappings[Editor.LastLoginDate] = LastLoginDateSelectionPanelComponent;
    this.mappings[Editor.DoubleNumber] = DoubleNumberEditorComponent;
  }

  ngOnInit(): void {
    if (this.editorConfig != undefined) {
      const editor = this.mappings[this.editorConfig.editor];

      this.viewContainerRef.clear();

      const cmp = this.viewContainerRef.createComponent<FilterPanelEditorComponent>(editor);

      cmp.instance.config = this.editorConfig;
      cmp.instance.parentItemKey = this.itemConfig.key;
      cmp.instance.parentFilterPanelKey = this.filterPanelConfig.key;
      cmp.instance.parentFilterPanel = this.parent;
      cmp.instance.ignoreKeyOnValueCollection = this.itemConfig.ignoreKeyOnValueCollection;

      this.parent.items.push({
        itemKey: this.itemConfig.key,
        editorKey: this.editorConfig.key,
        cmp: cmp.instance,
      });
    }
  }
}
