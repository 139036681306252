import {
  CreateErrorComponentService,
  DEFAULT_ERROR_LOCALIZATIONS,
  DEFAULT_ERROR_MESSAGES,
  ErrorScreenErrorCodes,
} from '@abp/ng.theme.shared';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private createErrorComponentService: CreateErrorComponentService) {}

  showError(status: ErrorScreenErrorCodes) {
    const key = `defaultError${status}`;
    const title = {
      key: DEFAULT_ERROR_LOCALIZATIONS[key]?.title,
      defaultValue: DEFAULT_ERROR_MESSAGES[key]?.title,
    };
    const message = {
      key: DEFAULT_ERROR_LOCALIZATIONS[key]?.details,
      defaultValue: DEFAULT_ERROR_MESSAGES[key]?.details,
    };

    const instance = {
      title: title,
      details: message,
      status: status,
    };

    this.createErrorComponentService.execute(instance);
  }
}
