import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { StringValidator } from 'src/core/validators/shared/string.validator';

@Component({
  selector: 'ca-twilio-integration-settings',
  templateUrl: './twilio-integration-settings.component.html',
  styleUrls: ['./twilio-integration-settings.component.scss'],
})
export class TwilioIntegrationSettingsComponent implements OnInit {
  form: FormGroup;

  private readonly prefix = 'TwilioIntegration';
  private readonly sourceName = `${this.prefix}.SourceName`;
  private readonly accountId = `${this.prefix}.AccountId`;
  private readonly authToken = `${this.prefix}.AuthToken`;
  private readonly workspaceId = `${this.prefix}.WorkspaceId`;
  private readonly migrationStartDate = `${this.prefix}.MigrationStartDate`;

  constructor(
    private fb: FormBuilder,
    private config: ConfigStateService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService
  ) {
    this.form = this.fb.group({
      sourceName: this.fb.group({
        name: this.sourceName,
        value: [
          this.config.getSetting(this.sourceName),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      accountId: this.fb.group({
        name: this.accountId,
        value: [
          this.config.getSetting(this.accountId),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      authToken: this.fb.group({
        name: this.authToken,
        value: [
          this.config.getSetting(this.authToken),
          {
            validators: [StringValidator.whitespace],
          },
        ],
      }),
      workspaceId: this.fb.group({
        name: this.workspaceId,
        value: [
          this.config.getSetting(this.workspaceId),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      migrationStartDate: this.fb.group({
        name: this.migrationStartDate,
        value: [
          this.config.getSetting(this.migrationStartDate),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
    });
  }

  ngOnInit(): void {}

  onSubmitForm(eventArgs): void {
    if (!this.form.valid) {
      return;
    }

    const settings: ConfigurationSettingsDto[] = [];

    settings.push({
      settingName: this.form.get('sourceName').get('name').value,
      settingValue: this.form.get('sourceName').get('value').value,
    });

    settings.push({
      settingName: this.form.get('accountId').get('name').value,
      settingValue: this.form.get('accountId').get('value').value,
    });

    if (this.form.get('authToken').get('value').value) {
      settings.push({
        settingName: this.form.get('authToken').get('name').value,
        settingValue: this.form.get('authToken').get('value').value,
      });
    }

    settings.push({
      settingName: this.form.get('workspaceId').get('name').value,
      settingValue: this.form.get('workspaceId').get('value').value,
    });

    settings.push({
      settingName: this.form.get('migrationStartDate').get('name').value,
      settingValue: this.form.get('migrationStartDate').get('value').value,
    });

    this.settingService.saveSetting(settings).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SavedSuccessfully')
      );
    });
  }
}
