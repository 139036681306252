import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserPhotoModule } from 'src/ca-shared/user-photo/user-photo.module';
import { MatDividerModule } from '@angular/material/divider';
import { CoreModule } from 'src/core/core.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { VideoCallSummaryComponent } from './video-call-summary.component';
import { ConversationIconContainerModule } from 'src/ca-shared/conversation-icon-container/conversation-icon-container.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [VideoCallSummaryComponent],
  imports: [
    CommonModule,
    SharedModule,
    UserPhotoModule,
    MatDividerModule,
    ClipboardModule,
    MatTooltipModule,
    CoreModule,
    NgbTooltipModule,
    ConversationIconContainerModule,
  ],
  exports: [VideoCallSummaryComponent],
})
export class VideoCallSummaryModule {}
