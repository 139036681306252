import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CrudService } from 'src/core/services/crud/crud.service';
import { MatTableColumnDefinitionModel } from 'src/core/models/mat-table/mat-table-column-definition.model';
import { UserFormEditTypes } from 'src/core/models/administration/user/user-form-edit-type.enum';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { CAUserDto } from 'src/core/models/administration/user/CAUser.dto';
import { LocalizationService } from '@abp/ng.core';
import { FilterPanelConfigurationModel } from 'src/ca-shared/filter-panel/filter-panel.module';
import { Operators } from 'src/core/models/request/operator.enum';
import { RoleDto } from 'src/core/models/shared/role.dto';
import { PageEvent } from '@angular/material/paginator';
import { DepartmentFilterModeOption } from 'src/core/models/generic-lookup-type/department/department-filter-mode-option.glt';
import { DepartmentDto } from 'src/core/models/administration/department/department.dto';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ca-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  @Output()
  showDetails: EventEmitter<{
    id: number;
    editType: string;
  }> = new EventEmitter();

  dataSource = new MatTableDataSource<CAUserDto>();
  data: CAUserDto[] = [];
  displayedColumns: string[] = [];
  totalCount = 0;

  skipCount = 0;
  pageSize = 25;
  currentPage = 0;

  filters: FilterItemDto[] = [];
  sorters: SorterItemDto[] = [];

  mainLayout = 'usersViewLayout';
  section = 'users';

  userFormEditTypes = UserFormEditTypes;

  private lastLoginDateValue: number;

  gridColumns: MatTableColumnDefinitionModel[] = [
    {
      columnName: 'username',
      header: this.localizationService.instant('User::Username'),
      binding: 'username',
      valueParser: undefined,
      pipes: undefined,
      width: '20%',
      minWidth: '150px',
      tooltip: undefined,
    },
    {
      columnName: 'fullName',
      header: this.localizationService.instant('User::FullName'),
      binding: 'fullname',
      valueParser: undefined,
      pipes: undefined,
      width: '20%',
      minWidth: '150px',
      tooltip: undefined,
    },
    {
      columnName: 'departmentName',
      header: this.localizationService.instant('::Department'),
      binding: 'department.name',
      valueParser: undefined,
      pipes: undefined,
      width: '20%',
      minWidth: '150px',
      tooltip: undefined,
    },
    {
      columnName: 'phoneNumber',
      header: this.localizationService.instant('User::DeviceIdPhone'),
      binding: 'phoneNumber',
      valueParser: undefined,
      pipes: undefined,
      width: '20%',
      minWidth: '150px',
      tooltip: undefined,
    },
    {
      columnName: 'email',
      header: this.localizationService.instant('User::E-mail'),
      binding: 'email',
      valueParser: undefined,
      pipes: undefined,
      width: '20%',
      minWidth: '250px',
      tooltip: undefined,
    },
    {
      columnName: 'isLockedOut',
      header: this.localizationService.instant('User::Locked'),
      binding: 'lockoutEnabled',
      valueParser: val => {
        return val
          ? this.localizationService.instant('AbpUi::Yes')
          : this.localizationService.instant('AbpUi::No');
      },
      pipes: undefined,
      width: '80px',
      tooltip: undefined,
    },
    {
      columnName: 'isActive',
      header: this.localizationService.instant('User::Status'),
      binding: 'isActive',
      valueParser: val => {
        return val
          ? this.localizationService.instant('User::Activate')
          : this.localizationService.instant('User::Deactivate');
      },
      pipes: undefined,
      width: '80px',
      tooltip: undefined,
    },
  ];

  private setRoleFilter(values: any, filters: FilterItemDto[]): FilterItemDto[] {
    if (values && values.length > 0) {
      const ids: string[] = [];

      for (let i = 0; i < values.length; i++) {
        const role = values[i] as RoleDto;
        ids.push(role.id);
      }

      filters.push({
        field: 'roleId',
        operator: this.operators.In,
        value: ids,
      });
    }

    return filters;
  }

  private setDepartmentFilter(values: any, filters: FilterItemDto[]) {
    if (values.department.selectedDepartments && values.department.selectedDepartments.length > 0) {
      const ids: number[] = [];
      let departmentOperator = this.operators.In;
      if (
        Number(values.department.filterModeId) ==
        DepartmentFilterModeOption.AllDepartmentsExceptSelectedDeparment
      ) {
        departmentOperator = this.operators.NotContains;
      }

      for (let i = 0; i < values.department.selectedDepartments.length; i++) {
        const department = values.department.selectedDepartments[i] as DepartmentDto;

        ids.push(department.id);
      }

      filters.push({
        field: 'departmentId',
        operator: departmentOperator,
        value: ids,
      });
    }
  }

  private setLoginDateFilter(values: any, filters: FilterItemDto[]) {
    if (values?.lastLoginDate?.id) {
      filters.push({
        field: 'lastLoginDate',
        operator: this.operators.Equals,
        value: values.lastLoginDate.id,
      });
    }
  }

  loadFromFilterPanel(args: { config: FilterPanelConfigurationModel; values: any }) {
    this.currentPage = 0;
    const filters: FilterItemDto[] = [];

    filters.push({
      field: 'quickSearchTerm',
      operator: this.operators.Equals,
      value: args.values.quickSearchTerm,
    });

    filters.push({
      field: 'includePassives',
      operator: this.operators.Equals,
      value: args.values.includePassives,
    });

    this.setRoleFilter(args.values.role, filters);
    this.setDepartmentFilter(args.values, filters);
    this.setLoginDateFilter(args.values, filters);

    this.doLoad(filters, []);
  }

  private doLoad(filters: FilterItemDto[], sorters: SorterItemDto[]) {
    this.filters = filters;
    this.sorters = sorters;

    this.service
      .get<CAUserDto>(CAUserDto, {
        filters,
        sorters: this.sorters,
        maxResultCount: this.pageSize,
        skipCount: this.pageSize * this.currentPage,
      })
      .subscribe(response => {
        this.data = this.skipCount === 0 ? response.items : this.data.concat(response.items);
        this.totalCount = response.totalCount;
        this.dataSource.connect().next(this.data);
      });
  }

  load() {
    this.service
      .get<CAUserDto>(CAUserDto, {
        filters: this.filters,
        sorters: this.sorters,
        maxResultCount: this.pageSize,
        skipCount: this.pageSize * this.currentPage,
      })
      .subscribe(response => {
        this.data = this.skipCount === 0 ? response.items : this.data.concat(response.items);
        this.totalCount = response.totalCount;
        this.dataSource.connect().next(this.data);
      });
  }

  openDetails(userId, editType) {
    this.showDetails.emit({
      id: userId,
      editType,
    });
  }

  onRowDoubleClick(eventArgs, userId) {
    this.openDetails(userId, UserFormEditTypes.detail);
  }

  selectRow(userId: string): void {
    this.clearSelection();

    const targetClass = 'user-row-' + userId;
    const selector = 'tr.' + targetClass;
    const rowEl: HTMLTableRowElement = document.querySelector(selector);

    rowEl.classList.add('selected');
  }

  clearSelection(): void {
    const selector = 'tr.selected';
    const rowEls: NodeListOf<HTMLTableRowElement> = document.querySelectorAll(selector);

    rowEls.forEach((rowEl: HTMLTableRowElement) => {
      rowEl.classList.remove('selected');
    });
  }

  public pageChanged(event?: PageEvent) {
    this.currentPage = event.pageIndex;
    this.load();
  }

  constructor(
    private service: CrudService,
    private operators: Operators,
    private localizationService: LocalizationService,
    private route: ActivatedRoute,
    private featureService: FeatureService
  ) {
    const ldapUserFeature = this.featureService.isEnabled(FeatureConstants.LdapLogin);

    const lastLoginDateParam = this.route.snapshot.queryParams.lastLoginDate;
    this.lastLoginDateValue = lastLoginDateParam !== undefined ? Number(lastLoginDateParam) : -1;
    if (ldapUserFeature) {
      this.gridColumns.push({
        columnName: 'ldapUser',
        header: this.localizationService.instant('User::LdapUser'),
        binding: 'ldapUser',
        valueParser: val => {
          return val
            ? this.localizationService.instant('::Yes')
            : this.localizationService.instant('::No');
        },
        pipes: undefined,
        width: '80px',
        tooltip: this.localizationService.instant('User::LdapUser'),
      });
    }

    for (const gridColumn of this.gridColumns) {
      this.displayedColumns.push(gridColumn.columnName);
    }
  }

  ngOnInit() {}
}
