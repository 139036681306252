import { LocalizationService } from '@abp/ng.core';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, take } from 'rxjs';
import { Select } from '@ngxs/store';
import { Sections } from 'src/core/models/administration/section.enum';
import { LayoutStateModel } from 'src/core/models/layout/layout.state-model';
import { SplitConfig } from 'src/core/models/split/split-config.model';
import { CALayoutState } from 'src/core/states/layout/ca-layout.state';
import { UserFormComponent } from '../user-form/user-form.component';
import { CrudService } from 'src/core/services/crud/crud.service';
import { UserSummaryComponent } from '../user-summary/user-summary.component';
import { UserDto } from 'src/core/models/shared/user.dto';
import { UserDetailToolbarComponent } from '../user-detail-toolbar/user-detail-toolbar.component';
import { WindowEvents } from 'src/core/constants/window-events.constant';
import { ToasterService } from '@abp/ng.theme.shared';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'ca-user-detail-main',
  templateUrl: './user-detail-main.component.html',
  styleUrls: ['./user-detail-main.component.scss'],
})
export class UserDetailMainComponent implements OnInit {
  splitConfig = SplitConfig.DEFAULT;

  user: UserDto = new UserDto();

  @Select(CALayoutState.layout(Sections.users))
  layout$: Observable<LayoutStateModel>;

  @ViewChild('form', { static: true, read: UserFormComponent })
  form: UserFormComponent;

  @ViewChild('summary', { static: true, read: UserSummaryComponent })
  summary: UserSummaryComponent;

  @ViewChild('toolbar', { static: true, read: UserDetailToolbarComponent })
  toolbar: UserDetailToolbarComponent;

  @HostListener(WindowEvents.BEFORE_UNLOAD)
  canDeactivate(): boolean | Observable<boolean> {
    return !this.form.hasUnsavedChanges();
  }

  constructor(
    private route: ActivatedRoute,
    private localizationService: LocalizationService,
    private snackBar: MatSnackBar,
    private service: CrudService,
    private router: Router,
    private toastr: ToasterService
  ) {
    const clientWidth = document.body.clientWidth;
    const formWidth = clientWidth / 4.2; // clientWidth > 1366 ? clientWidth / 4 : clientWidth / 3;

    this.splitConfig.gutterSize = 0;

    this.layout$.subscribe(l => {
      l.config.west.visible = true;
      l.config.west.size = formWidth;
    });
  }
  ngAfterViewInit(): void { }

  ngOnInit(): void {
    this.loadUser();
  }

  onSaveUserRequested(eventArgs: { user: UserDto; closeAfterSave: boolean }) {
    if (
      !eventArgs.user.extraProperties.ExternalId ||
      eventArgs.user.extraProperties.ExternalId == ''
    ) {
      eventArgs.user.extraProperties.ExternalId = crypto.randomUUID();
    }
    const userSaveRequest = this.service.save<UserDto>(UserDto, eventArgs.user);
    const permissionSaveRequest = this.form.savePermissions(eventArgs.user.id);

    combineLatest([userSaveRequest, permissionSaveRequest])
      .pipe(take(1))
      .subscribe({
        next: ([userSaveResponse]) => {
          this.form.removeUnsavedChanges();
          if (
            !userSaveResponse.extraProperties.IsActive &&
            userSaveResponse.extraProperties.LicenseActivated != null
          ) {
            this.toastr.warn(
              this.localizationService.instant('Licensing::PassiveUserLicenseWarning')
            );
          }
          if (!eventArgs.closeAfterSave) {
            this.form.processing = false;
            this.form.loadUser(userSaveResponse);
            this.summary.loadUser(userSaveResponse);
            this.form.setPermissionConfigState();
            if (eventArgs.user.id == '') {
              this.user = userSaveResponse;
              this.form.removeUnsavedChanges();
              this.router.navigate(['identity/users', userSaveResponse.id]);
            }
          } else {
            this.form.setPermissionConfigState();
            this.router.navigate(['identity/users']);
          }

          this.toastr.success(this.localizationService.instant('::SuccessfullySaved'));
        },
        error: () => {
          this.form.processing = false;
        },
      });
  }
  onDeleteUserRequested(eventArgs: { userId: string }) {
    this.service
      .delete<UserDto>(UserDto, eventArgs.userId)
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigate(['identity/users']);
      });
  }
  loadUser() {
    const userId = this.route.snapshot.params.id;
    this.snackBar.open(
      this.localizationService.instant('::PleaseWait'),
      this.localizationService.instant('::Loading'),
      {
        duration: 0,
        verticalPosition: 'top',
      }
    );

    if (userId === 'new') {
      this.user = new UserDto();
      this.user.extraProperties = new Object();
      this.user.extraProperties.IsActive = true;
      this.user.extraProperties.IsInteractive = true;
      this.user.id = '';
      this.form.loadUser(this.user);
      this.summary.loadUser(this.user);
      this.snackBar.dismiss();
    } else {
      const userRequest = this.service.getById<UserDto>(UserDto, userId);
      userRequest.pipe(take(1)).subscribe(result => {
        this.user = result;
        this.form.loadUser(result);
        this.summary.loadUser(result);
        this.snackBar.dismiss();
      });

      userRequest.pipe(take(1)).subscribe({
        next: result => {
          this.user = result;
          this.form.loadUser(result);
          this.summary.loadUser(result);
          this.snackBar.dismiss();
        },
        error: () => {
          this.snackBar.dismiss();
        },
      });
    }
    this.form.userForm.get('role').valueChanges.subscribe(role => {
      this.user.roleNames = role?.map(x => x.name);
    });
  }
}
