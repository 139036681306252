import { ConfigStateService, LocalizationService, PermissionService } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { PlayerComponent } from 'src/ca-shared/player/player.module';
import { EvaluationReferrer } from 'src/core/constants/evaluation-referrer.constant';
import { MatTableColumnDefinitionModel } from 'src/core/models/mat-table/mat-table-column-definition.model';
import { FormVersionDto } from 'src/core/models/quality-management/form-version.dto';
import { EvaluationDto } from 'src/core/models/quality/evaluation/evaluation.dto';
import { EvaluationMasterDto } from 'src/core/models/quality/evaluation/evaluation-master.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { CrudService } from 'src/core/services/crud/crud.service';
import { UserDto } from 'src/core/models/shared/user.dto';
import { EvaluationType } from 'src/core/models/generic-lookup-type/quality/evaluation-type.glt';
import { ScopeService } from 'src/core/services/administration/scope.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'ca-conversation-evaluation-list-panel',
  templateUrl: './conversation-evaluation-list-panel.component.html',
  styleUrls: ['./conversation-evaluation-list-panel.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ConversationEvaluationListPanelComponent implements OnInit {
  private _conversationId: number;

  evaluations: EvaluationDto[];
  evaluationReferrer = EvaluationReferrer;
  loadingDetail: any = {};
  expandedElement: EvaluationDto | null;

  canCalibrate: boolean;
  currentUserId: string;
  hasOnlyAgentScope: boolean;
  evaluationMasterMap: { [name: number]: EvaluationDto[] } = {};

  @Input()
  player: PlayerComponent;

  @Input()
  set conversationId(conversationId: number) {
    if (conversationId) {
      this._conversationId = conversationId;
      this.getEvaluations();
    }
  }

  get conversationId(): number {
    return this._conversationId;
  }
  displayedColumns: string[] = [];
  gridColumns: MatTableColumnDefinitionModel[] = [
    {
      columnName: 'icons',
      header: '',
      binding: 'id',
      valueParser: undefined,
      pipes: undefined,
      width: '4%',
      tooltip: undefined,
    },
    {
      columnName: 'menu',
      header: '',
      binding: 'id',
      valueParser: undefined,
      pipes: undefined,
      width: '3%',
      tooltip: 'Menu',
    },
    {
      columnName: 'creationTime',
      header: this.localizationService.instant('QualityManagement::EvaluationTime'),
      binding: 'creationTime',
      valueParser: val => {
        return new Date(val);
      },
      pipes: [
        {
          pipe: CADatePipe,
          args: [DateDisplayType.DateTimeWithoutSeconds, true],
        },
      ],
      width: '20%',
      tooltip: undefined,
    },
    {
      columnName: 'formVersion',
      header: this.localizationService.instant('QualityManagement::Form'),
      binding: 'evaluationMaster.formVersion',
      valueParser: (val: FormVersionDto) => {
        let formVersionStr = val.formName;
        formVersionStr += ' - V' + val.version;
        return formVersionStr;
      },
      pipes: undefined,
      width: '25%',
      tooltip: undefined,
    },
    {
      columnName: 'totalPoint',
      header: this.localizationService.instant('QualityManagement::Score'),
      binding: 'totalPoint',
      valueParser: (val, evaluation) => {
        val = val.toFixed(1);

        let scoreStr = val;
        scoreStr += ' / ' + evaluation.evaluationMaster.maxPoint;
        return scoreStr;
      },
      pipes: undefined,
      width: '25%',
      tooltip: undefined,
    },
  ];

  @Input()
  set activeTab(activeTab: number) {
    this._activeTab = activeTab;
  }

  get activeTab(): number {
    return this._activeTab;
  }

  private _activeTab: number;
  constructor(
    private service: CrudService,
    private localizationService: LocalizationService,
    private operators: Operators,
    private router: Router,
    private configStateService: ConfigStateService,
    private scopeService: ScopeService,
    private permissionService: PermissionService
  ) {
    this.hasOnlyAgentScope = this.scopeService.hasOnlyAgentScope();
    this.canCalibrate = this.permissionService.getGrantedPolicy('QualityManagement.CanCalibrate');

    if (!this.hasOnlyAgentScope) {
      this.gridColumns.splice(3, 0, {
        columnName: 'evaluator',
        header: this.localizationService.instant('QualityManagement::Evaluator'),
        binding: 'evaluator.fullname',
        valueParser: undefined,
        pipes: undefined,
        width: '20%',
        tooltip: undefined,
      });
    }

    for (const gridColumn of this.gridColumns) {
      this.displayedColumns.push(gridColumn.columnName);
    }
  }

  ngOnInit(): void {
    this.currentUserId = this.configStateService.getDeep('currentUser.id');
  }

  checkEditEvaluation(evaluation: EvaluationDto) {
    if (
      evaluation.firstEvaluatorId != null &&
      this.currentUserId == evaluation.evaluatorId &&
      evaluation.evaluationTypeId != EvaluationType.calibration &&
      !this.hasOnlyAgentScope
    ) {
      return true;
    }

    return false;
  }

  checkCalibration(evaluation: EvaluationDto) {
    if (
      evaluation.firstEvaluatorId == null ||
      !this.canCalibrate ||
      this.currentUserId == evaluation.firstEvaluatorId ||
      this.hasOnlyAgentScope
    ) {
      return false;
    }
    return true;
  }

  onCalibrateClick(evaluation: EvaluationDto) {
    const queryParams: any = {
      referrer: EvaluationReferrer.conversation,
      evaluationType: EvaluationType.calibration,
      evaluationId: evaluation.evaluationMaster.id,
      evaluationResultId: evaluation.id,
      referrerTab: this.activeTab,
      assignmentId: evaluation.evaluationMaster.assignmentId,
    };

    this.router.navigate(
      [
        'quality',
        'evaluate',
        'conversation',
        evaluation.evaluationMaster.conversationId,
        'with-form-version',
        evaluation.evaluationMaster.formId,
        evaluation.evaluationMaster.formVersionId,
      ],
      {
        queryParams: queryParams,
      }
    );
  }

  onEditEvaluationClick(evaluation: EvaluationDto) {
    const queryParams: any = {
      referrer: EvaluationReferrer.conversation,
      evaluationType: EvaluationType.reEvaluation,
      evaluationId: evaluation.evaluationMaster.id,
      assignmentId: evaluation.evaluationMaster.assignmentId,
      evaluationResultId: evaluation.id,
      referrerTab: this.activeTab,
    };

    this.router.navigate(
      [
        'quality',
        'evaluate',
        'conversation',
        evaluation.evaluationMaster.conversationId,
        'with-form-version',
        evaluation.evaluationMaster.formId,
        evaluation.evaluationMaster.formVersionId,
      ],
      {
        queryParams: queryParams,
      }
    );
  }

  getDetailList(evaluationMasterId: number) {
    return this.evaluationMasterMap[evaluationMasterId]
      ? this.evaluationMasterMap[evaluationMasterId]
      : [];
  }

  private getEvaluations() {
    if (this.conversationId) {
      const sorters: SorterItemDto[] = [];
      const filters: FilterItemDto[] = [];

      sorters.push({
        field: 'DateAndNameAsc',
        direction: '',
      });

      filters.push({
        field: 'conversationId',
        operator: this.operators.Equals,
        value: this.conversationId,
      });

      filters.push({
        field: 'automaticAssignments.isActive',
        operator: this.operators.Equals,
        value: true,
      });

      this.service
        .get<EvaluationMasterDto>(EvaluationMasterDto, {
          filters,
          sorters,
          maxResultCount: 99999,
          skipCount: 0,
        })
        .subscribe(response => {
          this.evaluations = [];
          this.evaluationMasterMap = [];
          response.items.forEach(master => {
            this.evaluationMasterMap[master.id] = [];
            master.evaluations.forEach(e => {
              if (master.automaticAssignmentId) {
                e.evaluator = new UserDto();
                e.evaluator.name = this.localizationService.instant('QualityManagement::Automatic');
              }
              this.evaluationMasterMap[master.id].push(e);
            });
            this.evaluationMasterMap[master.id] = this.evaluationMasterMap[master.id].sort(
              (first, second) => {
                if (first.creationTime < second.creationTime) {
                  return 1;
                }
                if (first.creationTime > second.creationTime) {
                  return -1;
                }
                return 0;
              }
            );
            let firstEvaluation: EvaluationDto;
            let lastEvaluation: EvaluationDto[];

            if (this.evaluationMasterMap[master.id].length > 0) {
              firstEvaluation =
                this.evaluationMasterMap[master.id][this.evaluationMasterMap[master.id].length - 1];
              lastEvaluation = this.evaluationMasterMap[master.id].splice(0, 1);
            }
            if (lastEvaluation && lastEvaluation.length > 0) {
              lastEvaluation[0].firstEvaluatorId = firstEvaluation?.evaluatorId;
              this.evaluations.push(lastEvaluation[0]);
            }
          });
        });
    }
  }
}
