import { ToasterService } from '@abp/ng.theme.shared';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { StringValidator } from 'src/core/validators/shared/string.validator';
import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { FeatureConstants } from 'src/core/constants/feature-constant';

@Component({
  selector: 'ca-speech-recognition-settings',
  templateUrl: './speech-recognition-settings.component.html',
  styleUrls: ['./speech-recognition-settings.component.scss'],
})
export class SpeechRecognitionSettingsComponent implements OnInit {
  form: FormGroup;
  trainingFeatureEnabled = false;

  private readonly prefix = 'SpeechRecognition';
  private readonly serviceUrl = this.prefix + '.ServiceUrl';
  private readonly tokenEndpoint = this.prefix + '.TokenEndpoint';
  private readonly clientId = this.prefix + '.ClientId';
  private readonly clientSecret = this.prefix + '.ClientSecret';
  private readonly modelName = this.prefix + '.ModelName';
  private readonly modelVersion = this.prefix + '.ModelVersion';
  private readonly sampleRate = this.prefix + '.SampleRate';
  private readonly tenant = this.prefix + '.Tenant';
  private readonly username = this.prefix + '.Username';
  private readonly password = this.prefix + '.Password';
  private readonly customerCode = this.prefix + '.CustomerCode';
  private readonly silenceTimeout = this.prefix + '.SilenceTimeout';
  private readonly pointsToSucceed = this.prefix + '.PointsToSucceed';
  private readonly maxNumberOfTriesToSkipRow = this.prefix + '.MaxNumberOfTriesToSkipRow';

  constructor(
    private fb: FormBuilder,
    private config: ConfigStateService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private featureService: FeatureService
  ) {
    this.trainingFeatureEnabled = this.featureService.isEnabled(FeatureConstants.Training);
    this.form = this.fb.group({
      serviceUrl: this.fb.group({
        name: this.serviceUrl,
        value: [
          this.config.getSetting(this.serviceUrl),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      tokenEndpoint: this.fb.group({
        name: this.tokenEndpoint,
        value: [
          this.config.getSetting(this.tokenEndpoint),
          {
            validators: [StringValidator.whitespace],
          },
        ],
      }),
      clientId: this.fb.group({
        name: this.clientId,
        value: [
          this.config.getSetting(this.clientId),
          {
            validators: [StringValidator.whitespace],
          },
        ],
      }),
      clientSecret: this.fb.group({
        name: this.clientSecret,
        value: [
          this.config.getSetting(this.clientSecret),
          {
            validators: [StringValidator.whitespace],
          },
        ],
      }),
      modelName: this.fb.group({
        name: this.modelName,
        value: [
          this.config.getSetting(this.modelName),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      modelVersion: this.fb.group({
        name: this.modelVersion,
        value: [
          this.config.getSetting(this.modelVersion),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      sampleRate: this.fb.group({
        name: this.sampleRate,
        value: [
          this.config.getSetting(this.sampleRate),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      tenant: this.fb.group({
        name: this.tenant,
        value: [
          this.config.getSetting(this.tenant),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      silenceTimeout: this.fb.group({
        name: this.silenceTimeout,
        value: [
          this.config.getSetting(this.silenceTimeout),
          {
            validators: [Validators.required, Validators.min(1000), Validators.max(5000)],
          },
        ],
      }),
      pointsToSucceed: this.fb.group({
        name: this.pointsToSucceed,
        value: [
          this.config.getSetting(this.pointsToSucceed),
          {
            validators: [Validators.required, Validators.min(0), Validators.max(100)],
          },
        ],
      }),
      maxNumberOfTriesToSkipRow: this.fb.group({
        name: this.maxNumberOfTriesToSkipRow,
        value: [
          this.config.getSetting(this.maxNumberOfTriesToSkipRow),
          {
            validators: [Validators.min(1), Validators.max(5)],
          },
        ],
      }),
    });
  }

  ngOnInit(): void {}

  onSubmitForm(eventArgs): void {
    if (!this.form.valid) {
      return;
    }

    const settings: ConfigurationSettingsDto[] = [];

    settings.push(
      {
        settingName: this.form.get('serviceUrl').get('name').value,
        settingValue: this.form.get('serviceUrl').get('value').value,
      },
      {
        settingName: this.form.get('tokenEndpoint').get('name').value,
        settingValue: this.form.get('tokenEndpoint').get('value').value,
      },
      {
        settingName: this.form.get('modelName').get('name').value,
        settingValue: this.form.get('modelName').get('value').value,
      },
      {
        settingName: this.form.get('modelVersion').get('name').value,
        settingValue: this.form.get('modelVersion').get('value').value,
      },
      {
        settingName: this.form.get('sampleRate').get('name').value,
        settingValue: this.form.get('sampleRate').get('value').value,
      },
      {
        settingName: this.form.get('tenant').get('name').value,
        settingValue: this.form.get('tenant').get('value').value,
      },
      {
        settingName: this.form.get('silenceTimeout').get('name').value,
        settingValue: this.form.get('silenceTimeout').get('value').value.toString(),
      },
      {
        settingName: this.form.get('pointsToSucceed').get('name').value,
        settingValue: this.form.get('pointsToSucceed').get('value').value.toString(),
      },
      {
        settingName: this.form.get('maxNumberOfTriesToSkipRow').get('name').value,
        settingValue: this.form.get('maxNumberOfTriesToSkipRow').get('value').value?.toString(),
      }
    );

    if (this.form.get('clientId').get('value').value) {
      settings.push({
        settingName: this.form.get('clientId').get('name').value,
        settingValue: this.form.get('clientId').get('value').value,
      });
    }

    if (this.form.get('clientSecret').get('value').value) {
      settings.push({
        settingName: this.form.get('clientSecret').get('name').value,
        settingValue: this.form.get('clientSecret').get('value').value,
      });
    }

    this.settingService.saveSetting(settings).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SavedSuccessfully')
      );
    });
  }
}
