import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxsModule } from '@ngxs/store';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ColumnOptionsButtonComponent } from './components/column-options-button/column-options-button.component';
import { ColumnConfiguratorModuleState } from './state/column-configurator-module.state';
import { ColumnConfiguratorComponent } from './components/column-configurator/column-configurator.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { SharedModule } from 'src/app/shared/shared.module';
import { ColumnSelectorComponent } from './components/column-selector/column-selector.component';
import { UserStorageEngine } from 'src/core/states/user-storage-engine';
import { ColumnResizerComponent } from './components/column-resizer/column-resizer.component';
export { ColumnConfiguratorComponent } from './components/column-configurator/column-configurator.component';
export { ColumnOptionsButtonComponent } from './components/column-options-button/column-options-button.component';
export { ColumnResizerComponent } from './components/column-resizer/column-resizer.component';
export { ColumnConfiguratorService } from './services/column-configurator.service';
export { GridConfig } from './models/grid-config.model';
export { ColumnConfig } from './models/column-config.model';

@NgModule({
  declarations: [
    ColumnOptionsButtonComponent,
    ColumnConfiguratorComponent,
    ColumnSelectorComponent,
    ColumnResizerComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    NgxsModule.forFeature([ColumnConfiguratorModuleState]),
    NgxsStoragePluginModule.forRoot(),
  ],
  exports: [ColumnOptionsButtonComponent, ColumnConfiguratorComponent, ColumnResizerComponent],
  providers: [
    {
      provide: STORAGE_ENGINE,
      useClass: UserStorageEngine,
    },
  ],
})
export class ColumnConfiguratorModule {}
