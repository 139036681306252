import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { UserMainComponent } from './user/user-main/user-main.component';
import { UserListToolbarComponent } from './user/user-list-toolbar/user-list-toolbar.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { AngularSplitModule } from 'angular-split';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from 'src/core/core.module';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { UserInteractionListComponent } from './user/user-interaction-list/user-interaction-list.component';
import { UserFormComponent } from './user/user-detail/user-form/user-form.component';
import { UserRoleSelectorComponent } from './user/user-role-selector/user-role-selector.component';
import { UserPermissionSelectorComponent } from './user/user-permission-selector/user-permission-selector.component';
import { CaSharedModule } from 'src/ca-shared/ca-shared.module';
import { DropdownTreeModule } from 'src/ca-shared/dropdown-tree/dropdown-tree.module';
import { UserPhotoModule } from 'src/ca-shared/user-photo/user-photo.module';
import { SelectorModule } from 'src/ca-shared/selector/selector.module';
import { UserRoutingModule } from './user/user-routing.module';
import { UserDetailMainComponent } from './user/user-detail/user-detail-main/user-detail-main.component';
import { UserDetailToolbarComponent } from './user/user-detail/user-detail-toolbar/user-detail-toolbar.component';
import { UserSummaryComponent } from './user/user-detail/user-summary/user-summary.component';
import { UserListFilterPanelComponent } from './user/user-list-filter-panel/user-list-filter-panel.component';
import { FilterPanelModule } from './../../ca-shared/filter-panel/filter-panel.module';
import { IconsModule } from 'src/ca-shared/icons/icons.module';
import { BlockUIModule } from 'ng-block-ui';
import { IdleUsersCountNotificationComponent } from './user/notifications/idle-users-count-notification/idle-users-count-notification.component';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [
    UserMainComponent,
    UserListToolbarComponent,
    UserListComponent,
    UserFormComponent,
    UserRoleSelectorComponent,
    UserInteractionListComponent,
    UserPermissionSelectorComponent,
    UserDetailMainComponent,
    UserDetailToolbarComponent,
    UserSummaryComponent,
    UserListFilterPanelComponent,
    IdleUsersCountNotificationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CaSharedModule,
    CoreModule,
    InfiniteScrollDirective,
    DropdownTreeModule,
    NgbModule,
    MatTableModule,
    AngularSplitModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatListModule,
    MatToolbarModule,
    MatExpansionModule,
    UserPhotoModule,
    SelectorModule,
    UserRoutingModule,
    FilterPanelModule,
    BlockUIModule,
    IconsModule,
    MatPaginatorModule,
  ],
  bootstrap: [IdleUsersCountNotificationComponent],
})
export class ReplacementModule {}
