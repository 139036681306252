<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div [class]="cssClass" container="body" placement="bottom" [formGroup]="form">
    <div class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label>{{ '(' + ('::AttachedData' | abpLocalization) + ')' }}</label>
      </div>
      <div
        class="col-auto"
        *blockUI="blockUiNameAttachedDataReadonly; template: blockTemplateReadonly">
        <label>{{ currentAttachedDataName }}</label>
      </div>
      <div class="col-auto">
        <label>{{ payload.operator | operator }}</label>
      </div>
      <div class="col-auto">
        <label>{{ payload.value }}</label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editableContent>
  <div [class]="cssClass" container="body" placement="bottom" [formGroup]="form">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label>{{ 'Conversation::QueryBuilder:AttachedData' | abpLocalization }}</label>
      </div>
      <div class="col-auto" *blockUI="blockUiNameAttachedData; template: blockTemplate">
        <ca-select
          class="attached-data-category"
          formControlName="attachedDataId"
          (change)="attachedDataSelectionChanged($event)"
          [optionStyle]="'max-width: 300px !important'"
          [(ngModel)]="payload.attachedDataId">
          <ca-option *ngFor="let attachedData of attachedDataList" [value]="attachedData.id">
            {{ attachedData.name }}
          </ca-option>
        </ca-select>
      </div>
      <div class="col-auto">
        <select
          class="form-select form-select-sm"
          [(ngModel)]="payload.operator"
          formControlName="operator">
          <option *ngFor="let operator of node.categoryItem.operators" [value]="operator">
            {{ operator | operator }}
          </option>
        </select>
      </div>
      <div class="col-auto" *blockUI="blockUiNameAttachedDataLookups; template: blockTemplate">
        <input
          *ngIf="!isSelectedAttachedDataMultipleChoice"
          type="text"
          [(ngModel)]="payload.value"
          class="form-control form-control-sm attached-data-category"
          formControlName="attachedDataValue" />
        <ca-select
          *ngIf="isSelectedAttachedDataMultipleChoice"
          class="attached-data-category"
          formControlName="attachedDataValue"
          (change)="attachedDataValueSelectionChanged($event)"
          [optionStyle]="'max-width: 300px !important'"
          [(ngModel)]="payload.value">
          <ca-option
            *ngFor="let attachedDataLookup of selectedAttachedDataLookupList"
            [value]="attachedDataLookup.value">
            {{ attachedDataLookup.value }}
          </ca-option>
        </ca-select>
      </div>
      <ca-query-builder-node-remove-button
        (buttonClick)="removeNode($event)"></ca-query-builder-node-remove-button>
    </div>
  </div>
</ng-template>

<ng-template #blockTemplate>
  <div class="d-flex justify-content-center align-items-center block-ui-template">
    <div class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">...</span>
    </div>
  </div>
</ng-template>

<ng-template #blockTemplateReadonly>
  <div class="d-flex justify-content-center align-items-center block-ui-template">
    <div class="spinner-border" style="width: 10px; height: 10px" role="status">
      <span class="sr-only">...</span>
    </div>
  </div>
</ng-template>
