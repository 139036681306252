import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { QueryBuilderCategoryDto } from 'src/core/models/query/query-builder-category.dto';
import { QueryGroupQueryItemDto } from 'src/core/models/query/query-group-query-item.dto';
import { IQueryBuilderCategoryItem } from 'src/core/models/query/query-builder-category-item.interface';
import { ConversationFilterableFields } from 'src/core/models/conversation/conversation-filterable-fields.enum';
import { QueryFieldDataType } from 'src/core/models/query/query-field-data-type.enum';
import { QueryConstants } from 'src/core/constants/query-constant';

@Component({
  selector: 'ca-query-tree-node-query-group',
  templateUrl: './query-tree-node-query-group.component.html',
  styleUrls: ['./query-tree-node-query-group.component.scss'],
})
export class QueryTreeNodeQueryGroupComponent extends QueryEditorBase implements OnInit {
  categories: QueryBuilderCategoryDto[];
  payload: QueryGroupQueryItemDto;

  onMenuOpened(eventArgs) {
    const nodeButtons = (
      this.viewContainerRef.element.nativeElement as HTMLElement
    ).querySelectorAll('.show-on-hover');

    nodeButtons.forEach(btn => {
      btn.classList.add('show-on-hover-disabled');
    });
  }

  onMenuClosed(eventArgs) {
    const nodeButtons = (
      this.viewContainerRef.element.nativeElement as HTMLElement
    ).querySelectorAll('.show-on-hover');

    nodeButtons.forEach((btn: HTMLElement) => {
      btn.classList.remove('show-on-hover-disabled');
      btn.style.display = 'none';
    });
  }

  onAddNodeClick(category: QueryBuilderCategoryDto, categoryItem: IQueryBuilderCategoryItem) {
    if (categoryItem.dataType === this.queryFieldDataType.QueryParser) {
      this.queryBuilder.requestQueryParse(this.node);
    } else {
      this.queryBuilder.addNodeFromAnotherNode(this.node, category, categoryItem);
    }
  }

  isCategoryItemVisible(categoryItem: IQueryBuilderCategoryItem): boolean {
    if (this.queryConstants.categoriesToHide.includes(categoryItem.dataType)) {
      return false;
    }

    if (!categoryItem.conversationTypes.includes(this.queryBuilder.conversationType)) {
      return false;
    }

    return (
      categoryItem.dataType !== this.queryFieldDataType.OrderedSimpleTerm &&
      (categoryItem.payload as any)?.field !== this.queryField.Conversation_Type
    );
  }

  isCategoryItemDisabled(categoryItem: IQueryBuilderCategoryItem): boolean {
    if (
      categoryItem.dataType == this.queryFieldDataType.MergeQuery &&
      this.queryBuilder.currentQuery &&
      this.queryBuilder.currentQuery.canMergeWithAnotherQuery === false
    ) {
      return true;
    } else {
      return false;
    }
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    public queryField: ConversationFilterableFields,
    public queryFieldDataType: QueryFieldDataType,
    private queryConstants: QueryConstants
  ) {
    super();
  }

  ngOnInit() {
    this.categories = this.queryBuilder.visibleCategories;
    this.payload = this.node.categoryItem.payload as QueryGroupQueryItemDto;
    this.node.validationStatus = true;
  }
}
