import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableColumnDefinitionModel } from 'src/core/models/mat-table/mat-table-column-definition.model';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { CrudService } from 'src/core/services/crud/crud.service';
import { LocalizationService } from '@abp/ng.core';
import { Store } from '@ngxs/store';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { PageEvent } from '@angular/material/paginator';
import { UserGroupDto } from 'src/core/models/user-group/user-group.dto';
import { take } from 'rxjs';

@Component({
  selector: 'ca-user-group-list',
  templateUrl: './user-group-list.component.html',
  styleUrls: ['./user-group-list.component.scss'],
})
export class UserGroupListComponent implements OnInit {
  @Output()
  showDetails: EventEmitter<{
    id: number;
  }> = new EventEmitter();

  @Output()
  listLoadRequestCompleted = new EventEmitter();

  dataSource = new MatTableDataSource<UserGroupDto>();
  data: UserGroupDto[] = [];
  displayedColumns: string[] = [];

  totalCount = 0;
  pageSize: number = 25;
  currentPage: number = 0;

  filters: FilterItemDto[] = [];
  sorters: SorterItemDto[] = [];

  gridColumns: MatTableColumnDefinitionModel[] = [
    {
      columnName: 'menu',
      header: '',
      binding: 'id',
      valueParser: undefined,
      pipes: undefined,
      width: '75px',
      tooltip: 'Menu',
    },
    {
      columnName: 'name',
      header: this.localizationService.instant('::Name'),
      binding: 'name',
      valueParser: undefined,
      pipes: undefined,
      width: '20%',
      tooltip: undefined,
    },
    {
      columnName: 'users',
      header: this.localizationService.instant('UserGroup::Users'),
      binding: 'userGroupUsers',
      valueParser: val => {
        return val.map(x => x.user?.fullname).join(', ');
      },
      pipes: undefined,
      width: '40%',
      tooltip: undefined,
    },
    {
      columnName: 'departments',
      header: this.localizationService.instant('UserGroup::Departments'),
      binding: 'userGroupDepartments',
      valueParser: val => {
        return val.map(x => x.department?.name).join(', ');
      },
      pipes: undefined,
      width: '40%',
      tooltip: undefined,
    },
    {
      columnName: 'lastModificationTime',
      header: this.localizationService.instant('::ModificationTime'),
      binding: 'lastModificationTime',
      valueParser: val => {
        return new Date(val);
      },
      pipes: [
        {
          pipe: CADatePipe,
          args: [DateDisplayType.DateTimeWithoutSeconds, true],
        },
      ],
      width: '150px',
      tooltip: undefined,
    },
    {
      columnName: 'active',
      header: this.localizationService.instant('UserGroup::Status'),
      binding: 'isActive',
      valueParser: val => {
        return val
          ? this.localizationService.instant('UserGroup::Active')
          : this.localizationService.instant('UserGroup::Passive');
      },
      pipes: undefined,
      width: '80px',
      tooltip: undefined,
    },
  ];

  onRowDoubleClick(eventArgs, userGroupId) {
    this.openDetails(userGroupId);
  }

  openDetails(userGroupId) {
    this.showDetails.emit({ id: userGroupId });
  }

  loadFromToolbar(filters: FilterItemDto[], sorters: SorterItemDto[]) {
    this.currentPage = 0;
    this.load(filters, sorters);
  }

  load(filters: FilterItemDto[], sorters: SorterItemDto[]) {
    this.filters = filters;
    this.sorters = sorters;
    this.service
      .get<UserGroupDto>(UserGroupDto, {
        maxResultCount: this.pageSize,
        skipCount: this.pageSize * this.currentPage,
        filters: this.filters,
        sorters: this.sorters,
      })
      .pipe(take(1))
      .subscribe({
        next: response => {
          this.data = response.items;
          this.totalCount = response.totalCount;
          this.dataSource.connect().next(this.data);
          this.listLoadRequestCompleted.emit();
        },
        error: () => {
          this.listLoadRequestCompleted.emit();
        },
      });
  }

  public pageChanged(event?: PageEvent) {
    this.currentPage = event.pageIndex;
    this.load(this.filters, this.sorters);
  }

  activate(userGroup: UserGroupDto) {
    this.service
      .update<UserGroupDto>(UserGroupDto, { id: userGroup.id }, 'activate')
      .pipe(take(1))
      .subscribe(() => {
        this.refresh();
      });
  }

  deactivate(userGroup: UserGroupDto): void {
    this.service
      .update<UserGroupDto>(UserGroupDto, { id: userGroup.id }, 'deactivate')
      .pipe(take(1))
      .subscribe(() => {
        this.refresh();
      });
  }

  refresh(): void {
    this.load(this.filters, this.sorters);
  }

  selectRow(userGroupId: number) {
    this.clearSelection();

    const targetClass = 'user-group-row-' + userGroupId;
    const selector = 'tr.' + targetClass;
    const rowEl: HTMLTableRowElement = document.querySelector(selector);

    rowEl.classList.add('selected');
  }

  clearSelection(): void {
    const selector = 'tr.selected';
    const rowEls: NodeListOf<HTMLTableRowElement> = document.querySelectorAll(selector);

    rowEls.forEach((rowEl: HTMLTableRowElement) => {
      rowEl.classList.remove('selected');
    });
  }

  constructor(
    private service: CrudService,
    private localizationService: LocalizationService,
    private store: Store
  ) {
    for (const gridColumn of this.gridColumns) {
      this.displayedColumns.push(gridColumn.columnName);
    }
  }

  ngOnInit(): void {}
}
