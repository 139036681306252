import { LocalizationService } from '@abp/ng.core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectorComponent } from 'src/ca-shared/selector/selector.module';
import { CAUserDto } from 'src/core/models/administration/user/CAUser.dto';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { StringQueryItemDto } from 'src/core/models/query/string-query-item.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';

import { CrudService } from 'src/core/services/crud/crud.service';
import { QueryService } from 'src/core/services/query/query.service';

@Component({
  selector: 'ca-query-tree-node-user-selector',
  templateUrl: './query-tree-node-user-selector.component.html',
  styleUrls: ['./query-tree-node-user-selector.component.scss'],
})
export class QueryTreeNodeUserSelectorComponent extends QueryEditorBase implements OnInit {
  isInitialLoad = true;
  payload: StringQueryItemDto;
  multipleValuePayloads: StringQueryItemDto[] = [];
  reservedIds: number[] = [];
  reservedInternalIds: number[] = [];
  userForm: FormGroup;
  users: CAUserDto[] = [];
  loading = true;

  get userFullNameList(): string {
    return this.users.map(x => x.fullname).join('\r\n');
  }

  constructor(
    private operators: Operators,
    private localizationService: LocalizationService,
    private fb: FormBuilder,
    private service: CrudService,
    private queryService: QueryService
  ) {
    super();

    this.userForm = fb.group(
      {
        agent: [null, { validators: [Validators.required], updateOn: 'change' }],
      },
      { updateOn: 'blur' }
    );

    this.userForm.get('agent').valueChanges.subscribe((val: CAUserDto[]) => {
      this.onChange(val);
    });

    this.userForm.statusChanges.subscribe(status => {
      this.node.validationStatus = status === 'VALID';
    });
  }

  ngOnInit() {
    this.payload = this.node.categoryItem.payload as StringQueryItemDto;
    this.multipleValuePayloads = Array.isArray(this.node.categoryItem.multipleValuePayloads)
      ? this.node.categoryItem.multipleValuePayloads.map(x => x as StringQueryItemDto)
      : [];

    // use old ids when the selection changes to prevent generating new ones
    this.reservedIds = this.multipleValuePayloads.map(x => x.id);
    this.reservedInternalIds = this.multipleValuePayloads.map(x => x.internalId);
  }

  ngAfterViewInit() {
    if (this.payload.value) {
      let values: string[] = [this.payload.value];

      this.multipleValuePayloads.forEach(x => {
        values.push(x.value);
      });

      this.service
        .get<CAUserDto>(CAUserDto, {
          filters: [
            {
              field: 'id',
              operator: this.operators.In,
              value: JSON.stringify(values),
            },
          ],
          maxResultCount: 9999,
          skipCount: 0,
          sorters: [],
        })
        .subscribe(response => {
          this.userForm.get('agent').setValue(response.items);
          this.users = response.items;

          this.loading = false;
        });
    }
  }

  onChange(selectedAgents: CAUserDto[]) {
    if (this.isInitialLoad) {
      this.isInitialLoad = false;
    } else {
      this.node.isDirty = true;
    }

    if (Array.isArray(selectedAgents) && selectedAgents.length > 0) {
      const copyOfReservedIds = new Array(...this.reservedIds);
      const copyOfReservedInternalIds = new Array(...this.reservedInternalIds);

      this.payload.value = selectedAgents[0].id;
      this.multipleValuePayloads = [];

      for (let i = 1; i < selectedAgents.length; i++) {
        const reservedId = copyOfReservedIds.pop();
        const reservedInternalId = copyOfReservedInternalIds.pop();

        const newPayload = Object.assign({}, this.payload);

        newPayload.internalId = reservedInternalId
          ? reservedInternalId
          : this.queryService.getInternalId();
        newPayload.parentInternalId = this.payload.internalId;
        newPayload.multiple = true;
        newPayload.value = selectedAgents[i].id;
        newPayload.id = reservedId ? reservedId : 0;

        this.multipleValuePayloads.push(newPayload);
      }

      this.node.categoryItem.multipleValuePayloads = new Array(...this.multipleValuePayloads);
    }
  }

  operatorChanged() {
    this.node.isDirty = true;
  }
}
