import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericLookupTypeService } from '../services/generic-lookup-type/generic-lookup-type.service';

@Injectable()
export class GenericLookupTypeResolver  {
    constructor(private genericLookupTypeService: GenericLookupTypeService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.genericLookupTypeService.load();
    }
}
