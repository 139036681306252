<div class="row display-inline" [formGroup]="editorForm" container="body">
  <div class="col-5" style="padding-right: 0px">
    <label
      *ngIf="actualConfig.label && actualConfig.label.length > 0"
      class="label-padding"
      [for]="'number-editor-' + this.actualConfig.key">
      {{ actualConfig.label | abpLocalization }}
    </label>
  </div>
  <div *ngIf="showValidationMessage" class="col no-padding-left">
    <div class="input-group">
      <div class="input-wrapper" style="margin-left: 2px">
        <input
          type="number"
          class="form-control form-control-sm"
          placeholder="min"
          [min]="actualConfig.minValue"
          [max]="actualConfig.maxValue"
          formControlName="editorLeftValue"
          (change)="onLeftValueChange($event.currentTarget.value)" />
      </div>
      <div class="input-wrapper">
        <input
          type="number"
          class="form-control form-control-sm"
          placeholder="max"
          [min]="actualConfig.minValue"
          [max]="actualConfig.maxValue"
          formControlName="editorRightValue"
          (change)="onRightValueChange($event.currentTarget.value)" />
      </div>
    </div>
  </div>
  <div *ngIf="!showValidationMessage" class="col no-padding-left">
    <div class="input-group">
      <div class="input-wrapper" style="margin-left: 2px">
        <input
          type="number"
          class="form-control form-control-sm"
          placeholder="min"
          formControlName="editorLeftValue"
          (change)="onLeftValueChange($event.currentTarget.value)" />
      </div>
      <div class="input-wrapper">
        <input
          type="number"
          class="form-control form-control-sm"
          placeholder="max"
          formControlName="editorRightValue"
          (change)="onRightValueChange($event.currentTarget.value)" />
      </div>
    </div>
  </div>
</div>
