import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { StringValidator } from 'src/core/validators/shared/string.validator';

@Component({
  selector: 'ca-puzzel-integration-settings',
  templateUrl: './puzzel-integration-settings.component.html',
  styleUrls: ['./puzzel-integration-settings.component.scss'],
})
export class PuzzelIntegrationSettingsComponent implements OnInit {
  form: FormGroup;

  private readonly prefix = 'PuzzelIntegration';
  private readonly sourceName = `${this.prefix}.SourceName`;
  private readonly customerKey = `${this.prefix}.CustomerKey`;
  private readonly userName = `${this.prefix}.UserName`;
  private readonly adminUserId = `${this.prefix}.AdminUserId`;
  private readonly password = `${this.prefix}.Password`;
  private readonly ftpUserName = `${this.prefix}.FtpUserName`;
  private readonly ftpPassword = `${this.prefix}.FtpPassword`;
  private readonly ftpRecordingPath = `${this.prefix}.FtpRecordingPath`;
  private readonly migrationStartDate = `${this.prefix}.MigrationStartDate`;
  private readonly fileExtension = `${this.prefix}.FileExtension`;

  constructor(
    private fb: FormBuilder,
    private config: ConfigStateService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService
  ) {
    this.form = this.fb.group({
      sourceName: this.fb.group({
        name: this.sourceName,
        value: [
          this.config.getSetting(this.sourceName),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      customerKey: this.fb.group({
        name: this.customerKey,
        value: [
          this.config.getSetting(this.customerKey),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      userName: this.fb.group({
        name: this.userName,
        value: [
          this.config.getSetting(this.userName),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      adminUserId: this.fb.group({
        name: this.adminUserId,
        value: [
          this.config.getSetting(this.adminUserId),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      password: this.fb.group({
        name: this.password,
        value: [
          this.config.getSetting(this.password),
          {
            validators: [StringValidator.whitespace],
          },
        ],
      }),
      ftpUserName: this.fb.group({
        name: this.ftpUserName,
        value: [
          this.config.getSetting(this.ftpUserName),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      ftpPassword: this.fb.group({
        name: this.ftpPassword,
        value: [
          this.config.getSetting(this.ftpPassword),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      ftpRecordingPath: this.fb.group({
        name: this.ftpRecordingPath,
        value: [
          this.config.getSetting(this.ftpRecordingPath),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      fileExtension: this.fb.group({
        name: this.fileExtension,
        value: [
          this.config.getSetting(this.fileExtension),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      migrationStartDate: this.fb.group({
        name: this.migrationStartDate,
        value: [
          this.config.getSetting(this.migrationStartDate),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
    });
  }

  ngOnInit(): void {}

  onSubmitForm(eventArgs): void {
    if (!this.form.valid) {
      return;
    }

    const settings: ConfigurationSettingsDto[] = [];

    settings.push({
      settingName: this.form.get('sourceName').get('name').value,
      settingValue: this.form.get('sourceName').get('value').value,
    });

    settings.push({
      settingName: this.form.get('customerKey').get('name').value,
      settingValue: this.form.get('customerKey').get('value').value,
    });

    settings.push({
      settingName: this.form.get('userName').get('name').value,
      settingValue: this.form.get('userName').get('value').value,
    });

    settings.push({
      settingName: this.form.get('ftpUserName').get('name').value,
      settingValue: this.form.get('ftpUserName').get('value').value,
    });

    if (this.form.get('ftpPassword').get('value').value) {
      settings.push({
        settingName: this.form.get('ftpPassword').get('name').value,
        settingValue: this.form.get('ftpPassword').get('value').value,
      });
    }

    settings.push({
      settingName: this.form.get('ftpRecordingPath').get('name').value,
      settingValue: this.form.get('ftpRecordingPath').get('value').value,
    });

    settings.push({
      settingName: this.form.get('fileExtension').get('name').value,
      settingValue: this.form.get('fileExtension').get('value').value,
    });

    settings.push({
      settingName: this.form.get('adminUserId').get('name').value,
      settingValue: this.form.get('adminUserId').get('value').value,
    });

    settings.push({
      settingName: this.form.get('password').get('name').value,
      settingValue: this.form.get('password').get('value').value,
    });

    settings.push({
      settingName: this.form.get('migrationStartDate').get('name').value,
      settingValue: this.form.get('migrationStartDate').get('value').value,
    });
    this.settingService.saveSetting(settings).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SavedSuccessfully')
      );
    });
  }
}
