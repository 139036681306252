<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div [class]="cssClass" [formGroup]="queryForm">
    <div class="row m-0 align-items-center full-height">
      <div class="col-auto" *ngIf="payload.not">
        <label style="font-style: italic">{{ 'Query::NOT' | abpLocalization }}</label>
      </div>
      <div class="col-auto">
        <label>{{ '(' + ('Query::NearQueryShortText' | abpLocalization) + ')' }}</label>
      </div>
      <div
        *ngIf="payload.sideId !== conversationSideAny"
        class="col-auto"
        ngbTooltip="{{ '::Channel' | abpLocalization }}"
        container="body"
        placement="bottom">
        <label>{{ 'GenericLookup::' + currentConversationSide | abpLocalization }}</label>
        <label>:</label>
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ '::FirstTerm' | abpLocalization }}"
        container="body"
        placement="left auto">
        <label>"</label>
        <label>{{ payload.firstTerm }}</label>
        <label *ngIf="payload.firstOperator === operators.Contains">
          {{ payload.firstOperator | operator }}
        </label>
        <label>"</label>
        <label>,&nbsp;</label>
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ '::SecondTerm' | abpLocalization }}"
        container="body"
        placement="left auto">
        <label>"</label>
        <label>{{ payload.secondTerm }}</label>
        <label *ngIf="payload.secondOperator === operators.Contains">
          {{ payload.secondOperator | operator }}
        </label>
        <label>"</label>
        <label>,</label>
      </div>
      <div class="col-auto">
        <label>{{ '::MaximumDistance' | abpLocalization }}</label>
        <label>:&nbsp;</label>
        <label>{{ payload.maximumDistance }}</label>
        <label>,</label>
      </div>

      <div class="col-auto">
        <label *ngIf="payload.searchInOrder" style="font-style: italic">
          {{ '::SearchinOrder' | abpLocalization }}
        </label>
      </div>
      <div class="col-auto d-flex" *ngIf="currentNearQueryType === NearQueryType.Interval">
        <label>{{ '::TimeRange' | abpLocalization }}:&nbsp;</label>
        <div
          class="col-auto"
          *ngIf="currentNearQueryType === NearQueryType.Interval"
          container="body"
          placement="bottom"
          ngbTooltip=" {{
            currentNearQueryIntervalType === NearQueryIntervalType.Opening
              ? getOpeningIntervalTooltip()
              : getClosingIntervalTooltip()
          }}">
          <label *ngIf="currentNearQueryIntervalType === NearQueryIntervalType.Opening">
            {{ 'Conversation::QueryBuilder:OpeningInterval' | abpLocalization }}
          </label>
          <label *ngIf="currentNearQueryIntervalType === NearQueryIntervalType.Closing">
            {{ 'Conversation::QueryBuilder:ClosingInterval' | abpLocalization }}
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editableContent>
  <div [class]="cssClass" [formGroup]="queryForm">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <div ngbDropdown class="d-inline-block">
          <button ngbDropdownToggle class="btn btn-sm ca-btn ca-btn-neutral-command">
            <i class="far fa-bars"></i>
          </button>
          <div ngbDropdownMenu>
            <button
              ngbDropdownItem
              class="command-menu-item"
              (click)="onQueryTypeMenuItemClicked(NearQueryType.Basic)">
              <i class="fas fa-circle small-icon"></i>
              <span>{{ 'Conversation::QueryBuilder:NearQuery' | abpLocalization }}</span>
            </button>
            <button
              *ngIf="queryBuilder.conversationType !== conversationType.email"
              ngbDropdownItem
              class="command-menu-item"
              (click)="onQueryTypeMenuItemClicked(NearQueryType.Interval)">
              <i class="fas fa-circle small-icon"></i>
              <span>{{ 'Conversation::QueryBuilder:NearQueryWithRange' | abpLocalization }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <label>{{ 'Query::NearQueryShortText' | abpLocalization }}</label>
      </div>
      <div class="col-auto">
        <mat-slide-toggle
          [(ngModel)]="payload.not"
          formControlName="not"
          [hidden]="conversationType.call !== queryBuilder.conversationType">
          {{ 'Query::NOT' | abpLocalization }}
        </mat-slide-toggle>
      </div>
      <div class="col-auto">
        <mat-slide-toggle [(ngModel)]="payload.searchInOrder" formControlName="searchInOrder">
          {{ '::SearchinOrder' | abpLocalization }}
        </mat-slide-toggle>
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ '::FirstTerm' | abpLocalization }}"
        container="body"
        placement="bottom">
        <input
          *ngIf="!queryBuilder.isPhraseSuggestionSupported"
          type="text"
          placeholder="{{ 'Query::QueryTermWatermark' | abpLocalization }}"
          class="form-control form-control-sm"
          formControlName="firstTerm" />
        <ca-phrase-suggestion-dropdown-selector
          *ngIf="queryBuilder.isPhraseSuggestionSupported"
          formControlName="firstTerm"
          [languageCode]="queryBuilder.languageCode"
          [class]="'form-control form-control-sm'"
          placeholder="{{ 'Query::QueryTermWatermark' | abpLocalization }}"
          [inputPhrases$]="inputPhrases$"
          (phraseRequest)="updateInputPhrases()"></ca-phrase-suggestion-dropdown-selector>
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ '::SecondTerm' | abpLocalization }}"
        container="body"
        placement="bottom">
        <input
          *ngIf="!queryBuilder.isPhraseSuggestionSupported"
          type="text"
          placeholder="{{ 'Query::NearQuerySecondTermWatermark' | abpLocalization }}"
          class="form-control form-control-sm"
          formControlName="secondTerm" />
        <ca-phrase-suggestion-dropdown-selector
          *ngIf="queryBuilder.isPhraseSuggestionSupported"
          formControlName="secondTerm"
          [languageCode]="queryBuilder.languageCode"
          [class]="'form-control form-control-sm'"
          placeholder="{{ 'Query::QueryTermWatermark' | abpLocalization }}"
          [inputPhrases$]="inputPhrases$"
          (phraseRequest)="updateInputPhrases()"></ca-phrase-suggestion-dropdown-selector>
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ 'Query::MaximumDistance' | abpLocalization }}"
        container="body"
        placement="bottom">
        <input
          type="number"
          min="1"
          max="10"
          style="width: 75px"
          class="form-control form-control-sm"
          formControlName="maximumDistance"
          [(ngModel)]="payload.maximumDistance" />
      </div>
      <div class="col-auto" [hidden]="queryBuilder.conversationType === conversationType.email">
        <ca-conversation-side-selector
          formControlName="conversationSide"
          [(ngModel)]="payload.sideId"></ca-conversation-side-selector>
      </div>
      <div
        class="col-auto radio-container"
        container="body"
        placement="bottom"
        *ngIf="currentNearQueryType === NearQueryType.Interval">
        <div ngbTooltip="{{ getOpeningIntervalTooltip() }}" container="body" placement="top auto">
          <input
            type="radio"
            class="radio-input"
            [attr.id]="'radioOpening_' + payload.internalId"
            #radioOpening
            value="{{ NearQueryIntervalType.Opening }}"
            formControlName="intervalType" />
          <label [attr.for]="'radioOpening_' + payload.internalId" class="radio-label">
            {{ 'Conversation::QueryBuilder:OpeningInterval' | abpLocalization }}
          </label>
        </div>
        <div
          style="margin-left: 12px"
          ngbTooltip="{{ getClosingIntervalTooltip() }}"
          container="body"
          placement="top auto">
          <input
            type="radio"
            class="radio-input"
            [attr.id]="'radioClosing_' + payload.internalId"
            #radioClosing
            value="{{ NearQueryIntervalType.Closing }}"
            formControlName="intervalType" />
          <label [attr.for]="'radioClosing_' + payload.internalId" class="radio-label">
            {{ 'Conversation::QueryBuilder:ClosingInterval' | abpLocalization }}
          </label>
        </div>
      </div>
      <div style="gap: 8px" class="col-auto d-flex flex-row justify-content-center">
        <button
          class="btn btn-sm ca-btn ca-btn-neutral-command"
          (click)="onClickDuplicate($event)"
          ngbTooltip="{{ '::Duplicate' | abpLocalization }} (Shift + Enter)"
          container="body"
          placement="bottom">
          <i class="far fa-copy"></i>
        </button>
        <ca-query-builder-node-remove-button
          (buttonClick)="removeNode($event)"></ca-query-builder-node-remove-button>
      </div>
    </div>
  </div>
</ng-template>
