import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlayerComponent } from './player/player.component';
import { PlayerControllerComponent } from './player-controller/player-controller.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PlayerTimelineCategoryLayerComponent } from './player-timeline-category-layer/player-timeline-category-layer.component';
import { PlayerTimelineCategoryMarkerComponent } from './player-timeline-category-marker/player-timeline-category-marker.component';
import { PlayerTimelineConversationMarkLayerComponent } from './player-timeline-conversationmark-layer/player-timeline-conversationmark-layer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PlayerTimelineConversationMarkMarkerComponent } from './player-timeline-conversationmark-marker/player-timeline-conversationmark-marker.component';
import { PlayerTimelineSentimentLayerComponent } from './player-timeline-sentiment-layer/player-timeline-sentiment-layer.component';
import { CompactPlayerComponent } from './compact-player/compact-player.component';
import { BlockUIModule } from 'ng-block-ui';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
export * from './player/player.component';
export * from './player-controller/player-controller.component';

@NgModule({
  declarations: [
    PlayerComponent,
    PlayerControllerComponent,
    PlayerTimelineCategoryLayerComponent,
    PlayerTimelineCategoryMarkerComponent,
    PlayerTimelineConversationMarkLayerComponent,
    PlayerTimelineConversationMarkMarkerComponent,
    PlayerTimelineSentimentLayerComponent,
    CompactPlayerComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatSliderModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    BlockUIModule,
    MatButtonToggleModule,
    NgbModule,
  ],
  exports: [
    PlayerControllerComponent,
    PlayerComponent,
    PlayerTimelineCategoryLayerComponent,
    CompactPlayerComponent,
  ],
})
export class PlayerModule {}
