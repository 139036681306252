<ng-select
  id="attachedData"
  [items]="multipleValueList"
  [(ngModel)]="selectedMultipleValueList"
  [searchFn]="customSearchFn"
  [multiple]="true"
  [maxSelectedItems]="maxSelectedItems"
  [placeholder]="'::SelectAttachedData' | abpLocalization"
  [closeOnSelect]="false"
  (change)="onItemSelect($event)">
  <ng-template ng-option-tmp let-item="item" let-index="index">
    <div class="d-flex align-items-center">
      <input
        id="item-{{ index }}"
        type="checkbox"
        [checked]="isSelected(item)"
        [disabled]="maxSelectedItems <= selectedMultipleValueList.length && !isSelected(item)" />
      <div
        class="ps-1 truncate-text"
        [ngbTooltip]="item.attachedData.name | abpLocalization"
        showTooltipIfTruncated>
        {{ item.attachedData.name | abpLocalization }}
      </div>
    </div>
  </ng-template>
  <ng-template ng-multi-label-tmp let-items="items"></ng-template>
</ng-select>
<div
  *ngIf="selectedMultipleValueList && selectedMultipleValueList.length > 0"
  class="pt-2 ps-2 pe-1 item-container">
  <ng-container *ngFor="let selectedMultipleValue of selectedMultipleValueList; index as i">
    <div class="d-flex flex-row row align-items-center pt-2">
      <div class="inner-item-container">
        <div class="d-flex row align-items-center">
          <div
            class="col-1 fas fa-times icon-container"
            (click)="onDeleteClick(selectedMultipleValue)"></div>
          <div
            class="col truncate-without-padding pe-0"
            [ngbTooltip]="selectedMultipleValue.attachedData.name"
            container="body"
            showTooltipIfTruncated>
            {{ selectedMultipleValue.attachedData.name }}
          </div>
        </div>
      </div>
      <div class="inner-item-container">
        <input
          *ngIf="!selectedMultipleValue.attachedData.isMultipleChoice"
          type="text"
          class="form-control form-control-sm"
          (change)="onItemValueChange(selectedMultipleValue, $event)"
          [placeholder]="'::EnterValue' | abpLocalization"
          [(ngModel)]="selectedMultipleValue.values[0].value" />
        <ng-select
          *ngIf="selectedMultipleValue.attachedData.isMultipleChoice"
          style="z-index: 64 !important"
          [id]="'attachedData-' + (i + 1)"
          class="attached-data-selector"
          [items]="selectedMultipleValue.attachedData.attachedDataLookups"
          (change)="applyChanges()"
          [(ngModel)]="selectedMultipleValue.values"
          [placeholder]="'::SelectValue' | abpLocalization"
          [multiple]="true"
          [searchable]="false"
          [closeOnSelect]="false"
          appendTo="body">
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div style="display: flex; align-items: center" container="body">
              <input
                id="item-value-{{ index }}"
                type="checkbox"
                [checked]="isValueSelected(selectedMultipleValue, item)" />
              <div
                class="ps-1 truncate-text"
                [ngbTooltip]="item.value | abpLocalization"
                showTooltipIfTruncated
                container="body">
                {{ item.value }}
              </div>
            </div>
          </ng-template>
          <ng-template ng-multi-label-tmp let-items="items">
            <div class="ng-value">
              <span class="ng-value-label">
                {{ '::XItemsSelected' | abpLocalization : items.length }}
              </span>
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </ng-container>
</div>
