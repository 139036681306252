import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserPhotoModule } from 'src/ca-shared/user-photo/user-photo.module';
import { CoreModule } from 'src/core/core.module';
import { ConversationCategoryPanelModule } from '../conversation-category-panel/conversation-category-panel.module';
import { ChatSummaryComponent } from './chat-summary.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ConversationIconContainerModule } from 'src/ca-shared/conversation-icon-container/conversation-icon-container.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [ChatSummaryComponent],
  imports: [
    CommonModule,
    SharedModule,
    ConversationCategoryPanelModule,
    UserPhotoModule,
    MatDividerModule,
    ClipboardModule,
    MatTooltipModule,
    CoreModule,
    NgbTooltipModule,
    ConversationIconContainerModule,
  ],
  exports: [ChatSummaryComponent],
})
export class ChatSummaryModule {}
