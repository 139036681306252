import { DtoHasApi } from '../../shared/dto-has-api.model';
import { Api } from 'src/core/decorators/dto/api.decorator';
import { DepartmentDto } from '../department/department.dto';

@Api('api/app/user')
export class CAUserDto extends DtoHasApi {
  constructor(data?: CAUserDto) {
    super();
    if (data != null) {
      this.id = data.id;
      this.tenantId = data.tenantId;
      this.userName = data.userName;
      this.password = data.password;
      this.name = data.name;
      this.surname = data.surname;
      this.email = data.email;
      this.emailConfirmed = data.emailConfirmed;
      this.phoneNumber = data.phoneNumber;
      this.phoneNumberConfirmed = data.phoneNumberConfirmed;
      this.isLockedOut = data.isLockedOut;
      this.lockoutEnabled = data.lockoutEnabled;
      this.roleNames = data.roleNames;
      this.concurrencyStamp = data.concurrencyStamp;
      this.twoFactorEnabled = data.twoFactorEnabled;
      this.departmentId = data.departmentId;
      this.ldapUser = data.ldapUser;
      this.middleName = data.middleName;
      this.fullname = data.fullname;
      this.isActive = data.isActive;
      this.pbxAgentId = data.pbxAgentId;
    }
  }

  id: string = '';
  twoFactorEnabled: boolean = false;
  concurrencyStamp: string = '';
  tenantId?: string = null;
  userName: string = '';
  password: string = null;
  name: string = '';
  surname: string = '';
  email: string = '';
  emailConfirmed: boolean;
  phoneNumber: string = '';
  phoneNumberConfirmed: boolean;
  isLockedOut: boolean;
  lockoutEnabled: boolean;
  roleNames: string[] = [];
  departmentId: number = 0;
  department: DepartmentDto;
  ldapUser: boolean;
  fullname: string = '';
  middleName: string = '';
  isActive: boolean;
  pbxAgentId: string = '';
  licenseActivated: boolean = false;
}
