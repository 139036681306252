import { FeatureService } from 'src/core/services/feature/feature.service';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard  {
  constructor(private featureService: FeatureService) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const features = (route.data.forFeatures ?? route.data.routes.forFeatures) as string[];

    return this.featureService.isEnabled(features);
  }
}
