<ng-template
  #previewQueryPopContent
  let-queryItems="queryItems"
  let-categories="categories"
  let-query="query">
  <div *ngIf="!query.lastQueryVersionIndexed">
    {{ 'Query::IndexingContinuesMessage' | abpLocalization }}
  </div>
  <ca-query-detail-builder
    #builder
    [isReadonly]="true"
    (abpInit)="builder.loadQueryItems(queryItems, categories, query)"></ca-query-detail-builder>
</ng-template>

<ng-template #previewTopicPopContent let-phrases="phrases">
  <div>{{ 'Category::CategoryPreviewInfo' | abpLocalization }}:</div>
  <br />
  <div>{{ phrases.join(', ') }}</div>
</ng-template>

<ng-template #previewQueryPopTitle let-query="query">
  <div>
    {{ query.name }}
  </div>
</ng-template>

<ng-template #loaded>
  <i class="fa-sharp fa-light fa-circle-info"></i>
</ng-template>

<ng-template #itemTemplate let-item="item">
  <div class="query-selection-panel-query-name">
    <div class="line-ellipsis">
      {{
        item.categoryType !== categoryType.Uncategorized
          ? item.name
          : ('Category::Uncategorized' | abpLocalization)
      }}
    </div>
    <div *ngIf="item.categoryType === categoryType.Query" class="query-selection-panel-info-button">
      <div
        [ngStyle]="{
          color: !item.lastQueryVersionIndexed ? 'rgb(255, 157, 9)' : 'rgb(0, 0, 0)'
        }"
        style="vertical-align: top"
        #popover="ngbPopover"
        [ngbPopover]="previewQueryPopContent"
        [popoverTitle]="previewQueryPopTitle"
        popoverClass="query-preview-popover"
        placement="right auto"
        triggers="manual"
        container="body"
        [autoClose]="'outside'"
        (click)="toggleQueryPreviewPopover(popover, item, $event)">
        <div *ngIf="isQueryPreviewLoading && item.queryId === previewQueryId; else loaded">
          <i class="fas fa-circle-notch fa-spin"></i>
        </div>
      </div>
    </div>
    <div *ngIf="item.categoryType === categoryType.Topic" class="query-selection-panel-info-button">
      <div
        type="button"
        style="vertical-align: top"
        #popover="ngbPopover"
        [ngbPopover]="previewTopicPopContent"
        [popoverTitle]="item.name"
        popoverClass="query-preview-popover"
        placement="right auto"
        triggers="manual"
        container="body"
        [autoClose]="'outside'"
        (click)="toggleTopicPreviewPopover(popover, item, $event)">
        <div *ngIf="isTopicPreviewLoading && item.topicId === previewTopicId; else loaded">
          <i class="fas fa-circle-notch fa-spin"></i>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #selectionTemplate let-selection="selection">
  <span *ngIf="selection.categoryType == categoryType.Uncategorized">
    {{ 'Category::Uncategorized' | abpLocalization }}
  </span>
  <span>
    {{ selection.name }}
  </span>
</ng-template>

<div class="category-selection-container">
  <div class="pt-2 d-flex" *ngIf="actualConfig.allowSortChange">
    <div class="d-flex align-items-center">{{ '::SortBy' | abpLocalization }}</div>
    <div class="flex-grow-1 ps-2">
      <ca-select [(ngModel)]="extraData.sortType" (change)="reload()" [items]="sortItems">
        <ca-option *ngFor="let sortItem of sortItems" [value]="sortItem.id">
          {{ 'GenericLookup::' + sortItem.label | abpLocalization }}
        </ca-option>
      </ca-select>
    </div>
  </div>
  <ng-container [formGroup]="editorForm">
    <ca-selector
      #categorySelector
      [url]="categoryDropdownOptions.url"
      [pageSize]="categoryDropdownOptions.pageSize"
      [itemTemplate]="itemTemplate"
      [selectionTemplate]="selectionTemplate"
      [queryOperator]="categoryDropdownOptions.queryOperator"
      [queryField]="categoryDropdownOptions.queryField"
      [emptyText]="categoryDropdownOptions.emptyText"
      [showSelections]="categoryDropdownOptions.showSelections"
      [showClearSelections]="categoryDropdownOptions.showClearSelections"
      [paginationDisabled]="true"
      [selectorPosition]="categoryDropdownOptions.selectorPosition"
      [showClearSelectionLink]="categoryDropdownOptions.showClearSelectionLink"
      [sorters]="categoryDropdownOptions.sorters"
      [styles]="{ width: '100%', 'border-width': '0px', height: '170px' }"
      [idProperty]="'name'"
      [cls]="'pt-2'"
      [multiple]="actualConfig.multiple"
      [maxSelectionCount]="actualConfig.maxSelectionCount"
      formControlName="editor"></ca-selector>
  </ng-container>
</div>
