import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { StringValidator } from 'src/core/validators/shared/string.validator';
import { VirtualTranslatorService } from 'src/core/models/generic-lookup-type/virtual-translator/services.glt';
import { map } from 'rxjs';
import { Store } from '@ngxs/store';

@Component({
  selector: 'virtual-translator-settings',
  templateUrl: './virtual-translator-settings.component.html',
  styleUrls: ['./virtual-translator-settings.component.scss'],
})
export class VirtualTranslatorSettingsComponent implements OnInit {
  form: FormGroup;
  serviceComboboxValue: string;
  serviceTypes: GenericLookupDto[];

  private readonly prefix = 'VirtualTranslator';
  private readonly service = this.prefix + '.Service';
  private readonly endpoint = this.prefix + '.Endpoint';

  constructor(
    private fb: FormBuilder,
    private config: ConfigStateService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private store: Store
  ) {
    this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(VirtualTranslatorService)))
      .subscribe(result => {
        this.serviceTypes = result;
      });

    this.serviceComboboxValue = this.config.getSetting(this.service);

    this.form = this.fb.group({
      service: [this.serviceComboboxValue, Validators.required],
      endpoint: this.fb.group({
        name: this.endpoint,
        value: [
          this.config.getSetting(this.endpoint),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
    });
  }

  isServiceSelected(serviceName): boolean {
    const serviceValue = this.form?.get('service').value;
    return serviceValue && serviceValue === serviceName;
  }

  ngOnInit(): void {
    this.updateEndpointValidator(this.serviceComboboxValue);
  }

  onSubmitForm(eventArgs): void {
    if (!this.form.valid) {
      return;
    }
    const settings: ConfigurationSettingsDto[] = [];

    settings.push(
      {
        settingName: this.service,
        settingValue: this.form.get('service').value,
      },
      {
        settingName: this.form.get('endpoint').get('name').value,
        settingValue: this.form.get('endpoint').get('value').value,
      }
    );

    this.settingService.saveSetting(settings).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SavedSuccessfully')
      );
    });
  }

  onTypeChange(service: string): void {
    this.updateEndpointValidator(service);
  }

  updateEndpointValidator(service) {
    const endpointControl = this.form.get('endpoint')?.get('value');
  
    if (service === 'VirtualTranslator.Service.KnovvuIVR') {
      endpointControl?.setValidators([Validators.required]);
    } else {
      endpointControl?.clearValidators();
    }
    endpointControl?.updateValueAndValidity();
  }
  
}
