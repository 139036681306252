<div>
  <div class="row g-1" [formGroup]="editorForm" [style]="disabled ? 'pointer-events: none;' : ''">
    <div class="col">
      <ca-datetime-picker
        formControlName="start"
        [customClass]="'ca-dropdown-menu-z-1055'"
        [minDate]="minDate"
        [maxDate]="value.end"
        [hideTime]="hideTime"></ca-datetime-picker>
    </div>
    <div class="col" [hidden]="disableEndDate">
      <ca-datetime-picker
        formControlName="end"
        [customClass]="'ca-dropdown-menu-z-1055'"
        [minDate]="value.start"
        [maxDate]="maxDate"
        [hideTime]="hideTime"></ca-datetime-picker>
    </div>
    <div *ngIf="useNoEndDate" class="col" style="margin-top: 8px; margin-bottom: 8px">
      <input
        #noEndDateCheckBox
        type="checkbox"
        id="noEndDate"
        [disabled]="disabled"
        (change)="onNoEndDateChange($event.currentTarget.checked)"
        [checked]="noEndDateChecked" />
      <label class="ps-1" for="noEndDate">
        {{ 'CA::NoEndDate' | abpLocalization }}
      </label>
    </div>
  </div>
  <div class="row" [hidden]="!useShortcuts" dir="ltr">
    <div
      class="col mt-3"
      style="text-align: center; padding-left: 0px !important"
      [style]="disabled ? 'pointer-events: none;' : ''">
      <button class="ca-btn ca-btn-link ca-btn-link-sm p-0" (click)="onShortcutClick(-1)">
        <i class="bi-dot"></i>
        <span #shortcutSpan [attr.shortcut]="-1">
          {{ 'DateTimeRangePicker::Yesterday' | abpLocalization }}
        </span>
      </button>
      <button
        type="button"
        class="ca-btn ca-btn-link ca-btn-link-sm p-0"
        (click)="onShortcutClick(0)">
        <i class="bi-dot"></i>
        <span #shortcutSpan [attr.shortcut]="0">
          {{ 'DateTimeRangePicker::Today' | abpLocalization }}
        </span>
      </button>
      <button
        type="button"
        class="ca-btn ca-btn-link ca-btn-link-sm p-0"
        (click)="onShortcutClick(-7)">
        <i class="bi-dot"></i>
        <span #shortcutSpan [attr.shortcut]="-7">
          {{ 'DateTimeRangePicker::Last7Days' | abpLocalization }}
        </span>
      </button>
      <button
        type="button"
        class="ca-btn ca-btn-link ca-btn-link-sm p-0"
        (click)="onShortcutClick(-30)">
        <i class="bi-dot"></i>
        <span #shortcutSpan [attr.shortcut]="-30">
          {{ 'DateTimeRangePicker::Last30Days' | abpLocalization }}
        </span>
      </button>
    </div>
  </div>
</div>
