<div [style]="isFilterPanel ? 'width: fit-content' : ''">
  <div class="form-control form-control-sm conversation-side-selector pt-0 pl-0">
    <mat-select
      [(value)]="value"
      [disabled]="isDisabled"
      hideSingleSelectionIndicator="true"
      (selectionChange)="onSelectionChange($event.value)"
      panelClass="mat-select-position conversation-side-panel-class"
      disableOptionCentering>
      <mat-option *ngFor="let side of conversationSides$ | async" [value]="side.id">
        <div class="mat-option-container">
          <div class="icon-container">
            <div
              class="w-100"
              *ngIf="allSide && side.id == (getLookup('Conversation.Side.All') | async).id">
              <span class="boder-multiple">
                <span>
                  <i class="far fa-headphones icon-size conversation-side-agent-color"></i>
                </span>
                <span class="icon-margin">
                  <i class="far fa-user icon-size conversation-side-customer-color"></i>
                </span>
              </span>
            </div>
            <div
              class="w-100"
              *ngIf="anySide && side.id == (getLookup('Conversation.Side.Any') | async).id">
              <div class="float-start">
                <span class="boder-single">
                  <i class="far fa-headphones icon-size conversation-side-agent-color"></i>
                </span>
              </div>
              <div class="float-end">
                <span class="boder-single">
                  <i class="far fa-user icon-size conversation-side-customer-color"></i>
                </span>
              </div>
            </div>
            <div
              class="w-100"
              *ngIf="side.id == (getLookup('Conversation.Side.Customer') | async).id">
              <div class="float-end">
                <span class="boder-single">
                  <i class="far fa-user icon-size conversation-side-customer-color"></i>
                </span>
              </div>
            </div>
            <div class="w-100" *ngIf="side.id == (getLookup('Conversation.Side.Agent') | async).id">
              <div class="float-end">
                <span class="boder-single">
                  <i class="far fa-headphones icon-size conversation-side-agent-color"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="ms-1">{{ 'GenericLookup::' + side.name + '.Label' | abpLocalization }}</div>
        </div>
      </mat-option>

      <mat-select-trigger>
        <div
          class="align-left mt-1"
          ngbTooltip="{{ getTooltipText() }}"
          container="body"
          placement="bottom">
          <span
            class="boder-multiple-small smaller-font"
            *ngIf="allSide && value == (getLookup('Conversation.Side.All') | async).id">
            <span>
              <i
                class="far fa-headphones icon-size conversation-side-agent-color"
                style="padding-left: 0.5px"></i>
            </span>
            <span class="ms-1">
              <i
                class="far fa-user icon-size conversation-side-customer-color"
                style="padding-left: 1.4px"></i>
            </span>
          </span>
          <span
            class="w-100"
            *ngIf="anySide && value == (getLookup('Conversation.Side.Any') | async).id">
            <span class="boder-single-small smaller-font" style="margin-right: 6px">
              <i
                class="far fa-headphones icon-size conversation-side-agent-color"
                style="padding-left: 0.5px"></i>
            </span>
            <span class="boder-single-small smaller-font">
              <i
                class="far fa-user icon-size conversation-side-customer-color"
                style="padding-left: 1.4px"></i>
            </span>
          </span>
          <span
            class="boder-single-small smaller-font"
            *ngIf="value == (getLookup('Conversation.Side.Customer') | async).id">
            <i
              class="far fa-user icon-size conversation-side-customer-color"
              style="padding-left: 1.4px"></i>
          </span>
          <span
            class="boder-single-small smaller-font"
            *ngIf="value == (getLookup('Conversation.Side.Agent') | async).id">
            <i
              class="far fa-headphones icon-size conversation-side-agent-color"
              style="padding-left: 0.5px"></i>
          </span>
        </div>
      </mat-select-trigger>
    </mat-select>
  </div>
</div>
