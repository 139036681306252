import { Environment } from '@abp/ng.core';

const baseUrl = window.location.origin;

// Workaround for preserve identityserver redirect uri.
function getRedirectUrl(): string {
  const identityParams = ['code', 'scope', 'state', 'session_state'];
  let url = window.location.origin + window.location.pathname;
  let params = new URLSearchParams(window.location.search);
  params.forEach((value, key) => {
    if (identityParams.indexOf(key) === -1) {
      if (url.indexOf('?') < 0) {
        url += '?';
      }
      url += key + '=' + value;
    }
  });
  return url;
}

const oAuthConfig = {
  issuer: '/ca-identity/',
  skipIssuerCheck: true,
  strictDiscoveryDocumentValidation: false,
  redirectUri: window.location.origin,
  clientId: 'CA_App',
  responseType: 'code',
  scope: 'offline_access CA',
  showDebugInformation: true,
  requireHttps: false,
  impersonation: {
    userImpersonation: true,
    tenantImpersonation: true,
  },
};

export const environment = {
  production: true,
  application: {
    baseUrl,
    name: 'Conversational Analytics | Sestek',
    logoUrl: 'assets/images/logo/sestek-light.png',
    stateVersion: '2.9.2',
  },
  oAuthConfig,
  apis: {
    default: {
      url: '',
      rootNamespace: 'Sestek.CA',
    },
    AbpAccountPublic: {
      url: oAuthConfig.issuer,
      rootNamespace: 'AbpAccountPublic',
    },
  },
  stateNameSpace: 'CA',
} as Environment;
