import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BlockUIService } from 'ng-block-ui';
import { take } from 'rxjs';
import { AttachedDataLookupDto } from 'src/core/models/administration/attached-data/attached-data-lookup.dto';
import { AttachedDataDto } from 'src/core/models/administration/attached-data/attached-data.dto';
import { AttachedDataQueryItemDto } from 'src/core/models/query/attached-data-query-item.dto';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { AttachedDataService } from 'src/core/services/administration/attached-data.service';
import { CrudService } from 'src/core/services/crud/crud.service';

@Component({
  selector: 'ca-query-tree-node-attached-data',
  templateUrl: './query-tree-node-attached-data.component.html',
  styles: [
    `
      :host >>> div.block-ui-wrapper {
        background-color: rgba(255, 255, 255, 0.7);
      }

      :host >>> div.block-ui-template {
        top: 50%;
      }
    `,
  ],
  styleUrls: ['./query-tree-node-attached-data.component.scss']
})
export class QueryTreeNodeAttachedDataComponent extends QueryEditorBase implements OnInit {
  blockUiNameAttachedDataLookups: string;
  blockUiNameAttachedData: string;
  blockUiNameAttachedDataReadonly: string;

  attachedDataList: AttachedDataDto[];
  payload: AttachedDataQueryItemDto;
  form: FormGroup = null;
  currentAttachedDataName: string;
  selectedAttachedDataId: number;
  selectedAttachedDataLookupList: AttachedDataLookupDto[] = [];

  constructor(
    private service: CrudService,
    private operators: Operators,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private blockUiService: BlockUIService,
    private attachedDataService: AttachedDataService
  ) {
    super();

    this.form = formBuilder.group(
      {
        attachedDataValue: [
          null,
          {
            validators: [Validators.required],
          },
        ],
        operator: [null],
        attachedDataId: [null, Validators.required],
      },
      { updateOn: 'blur' }
    );

    this.form.statusChanges.subscribe(status => {
      this.node.validationStatus = status === 'VALID';
    });

    this.form.valueChanges.subscribe(() => {
      this.node.isDirty = this.form.dirty;
    });
  }

  async ngOnInit() {
    this.payload = this.node.categoryItem.payload as AttachedDataQueryItemDto;
    this.setupBlockUiNames();

    const filters: FilterItemDto[] = [];
    const sorters: SorterItemDto[] = [];

    const blockName: string = this.isReadonly
      ? this.blockUiNameAttachedDataReadonly
      : this.blockUiNameAttachedData;
    this.blockUiService.start(blockName);

    this.attachedDataService
      .getAttachedDataList()
      .pipe(take(1))
      .subscribe({
        next: data => {
          if (this.payload.attachedDataId > 0 && data !== undefined && data.items.length > 0) {
            this.attachedDataList = data.items.filter(
              item => item.id === this.payload.attachedDataId || item.visible
            );
          } else if (
            this.payload.attachedDataId === 0 &&
            data !== undefined &&
            data.items.length > 0
          ) {
            this.attachedDataList = data.items.filter(item => item.visible);
            // set default select option
            this.payload.attachedDataId = this.attachedDataList[0].id;
          }

          if (this.payload.attachedDataId != null && this.payload.attachedDataId != 0 && data) {
            this.currentAttachedDataName = data.items.find(
              x => x.id == this.payload.attachedDataId
            ).name;
            this.selectedAttachedDataId = this.payload.attachedDataId;
            this.arrangeAttachedDataInput();
          }

          this.node.validationStatus = this.form.valid;
        },
        error: () => { },
        complete: () => {
          // finally
          this.blockUiService.stop(blockName);
        },
      });
  }

  private setupBlockUiNames() {
    this.blockUiNameAttachedDataLookups = `block-attached-data-lookups-${this.payload.internalId}`;
    this.blockUiNameAttachedData = `block-attached-data-${this.payload.internalId}`;
    this.blockUiNameAttachedDataReadonly = `block-attached-data-readonly-${this.payload.internalId}`;
  }

  get selectedAttachedData(): AttachedDataDto {
    if (this.selectedAttachedDataId != null && this.selectedAttachedDataId > 0) {
      return this.attachedDataList.find(a => a.id == this.selectedAttachedDataId);
    } else {
      return null;
    }
  }

  get isSelectedAttachedDataMultipleChoice(): boolean {
    const result = this.selectedAttachedData != null && this.selectedAttachedData.isMultipleChoice;
    return result;
  }

  attachedDataSelectionChanged(attachedDataId: number): void {
    this.selectedAttachedDataId = attachedDataId;
    this.payload.attachedDataId = attachedDataId;
    this.form.get('attachedDataValue').reset();
    this.form.get('attachedDataId').patchValue(attachedDataId);
    this.arrangeAttachedDataInput();
  }

  attachedDataValueSelectionChanged(attachedDataValue): void {
    this.payload.value = attachedDataValue;
    this.form.get('attachedDataValue').patchValue(attachedDataValue);
  }

  arrangeAttachedDataInput(): void {
    if (this.isSelectedAttachedDataMultipleChoice) {
      this.blockUiService.start(this.blockUiNameAttachedDataLookups);
      this.attachedDataService
        .getAttachedDataLookups(this.selectedAttachedDataId)
        .pipe(take(1))
        .subscribe({
          next: response => {
            this.selectedAttachedDataLookupList = response;
            if (this.selectedAttachedDataLookupList.length > 0) {
              this.form
                .get('attachedDataValue')
                .setValue(this.selectedAttachedDataLookupList[0].value);
            }
          },
          error: () => { },
          complete: () => {
            // finally
            this.blockUiService.stop(this.blockUiNameAttachedDataLookups);
          },
        });
    } else {
      this.selectedAttachedDataLookupList = [];
    }
  }
}
