import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'Conversation.RedactionType'
})
export class ConversationRedactionType {
    @GenericLookup({ code: 'Conversation.RedactionType.Dynamic' })
    static dynamic: number;

    @GenericLookup({ code: 'Conversation.RedactionType.Static' })
    static static: number;
}