import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { StringValidator } from 'src/core/validators/shared/string.validator';

@Component({
  selector: 'ca-alotech-v3-integration-settings',
  templateUrl: './alotech-v3-integration-settings.component.html',
  styleUrls: ['./alotech-v3-integration-settings.component.scss'],
})
export class AloTechV3IntegrationSettingsComponent implements OnInit {
  form: FormGroup;

  private readonly prefix = 'AloTechV3Integration';
  private readonly clientId = `${this.prefix}.ClientId`;
  private readonly clientSecret = `${this.prefix}.ClientSecret`;
  private readonly tenantName = `${this.prefix}.TenantName`;
  private readonly sourceName = `${this.prefix}.SourceName`;
  private readonly migrationStartDate = `${this.prefix}.MigrationStartDate`;
  private readonly useQueueFilter = `${this.prefix}.UseQueueFilter`;
  private readonly queueFilterDictionary = `${this.prefix}.QueueFilterDictionary`;
  private readonly useAgentFilter = `${this.prefix}.UseAgentFilter`;
  private readonly agentFilterDictionary = `${this.prefix}.AgentFilterDictionary`;
  private readonly useCustomFieldFilter = `${this.prefix}.UseCustomFieldFilter`;
  private readonly customFieldFilterDictionary = `${this.prefix}.CustomFieldFilterDictionary`;
  private readonly defaultLanguageCode = `${this.prefix}.DefaultLanguageCode`;
  private readonly languageCodeTags = `${this.prefix}.LanguageCodeTags`;

  useQueueFilterCb = false;
  useAgentFilterCb = false;
  useCustomFieldFilterCb = false;

  constructor(
    private fb: FormBuilder,
    private config: ConfigStateService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService
  ) {
    this.useQueueFilterCb = config.getSetting(this.useQueueFilter) == 'true';
    this.useAgentFilterCb = config.getSetting(this.useAgentFilter) == 'true';
    this.useCustomFieldFilterCb = config.getSetting(this.useCustomFieldFilter) == 'true';

    this.form = this.fb.group({
      clientId: this.fb.group({
        name: this.clientId,
        value: [
          this.config.getSetting(this.clientId),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      clientSecret: this.fb.group({
        name: this.clientSecret,
        value: [
          this.config.getSetting(this.clientSecret),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      tenantName: this.fb.group({
        name: this.tenantName,
        value: [
          this.config.getSetting(this.tenantName),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      sourceName: this.fb.group({
        name: this.sourceName,
        value: [
          this.config.getSetting(this.sourceName),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      migrationStartDate: this.fb.group({
        name: this.migrationStartDate,
        value: [
          this.config.getSetting(this.migrationStartDate),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      defaultLanguageCode: this.fb.group({
        name: this.defaultLanguageCode,
        value: [
          this.config.getSetting(this.defaultLanguageCode),
          {
            validators: [StringValidator.whitespace],
          },
        ],
      }),
      languageCodeTags: this.fb.group({
        name: this.languageCodeTags,
        value: [
          this.config.getSetting(this.languageCodeTags),
          {
            validators: [StringValidator.whitespace, StringValidator.json],
          },
        ],
      }),
      useQueueFilter: this.fb.group({
        name: this.useQueueFilter,
        value: [this.config.getSetting(this.useQueueFilter)],
      }),
      queueFilterDictionary: this.fb.group({
        name: this.queueFilterDictionary,
        value: [
          this.config.getSetting(this.queueFilterDictionary),
          {
            validators: [StringValidator.json],
          },
        ],
      }),
      useAgentFilter: this.fb.group({
        name: this.useAgentFilter,
        value: [this.config.getSetting(this.useAgentFilter)],
      }),
      agentFilterDictionary: this.fb.group({
        name: this.agentFilterDictionary,
        value: [this.config.getSetting(this.agentFilterDictionary)],
      }),
      useCustomFieldFilter: this.fb.group({
        name: this.useCustomFieldFilter,
        value: [this.config.getSetting(this.useCustomFieldFilter)],
      }),
      customFieldFilterDictionary: this.fb.group({
        name: this.customFieldFilterDictionary,
        value: [
          this.config.getSetting(this.customFieldFilterDictionary),
          {
            validators: [StringValidator.json],
          },
        ],
      }),
    });
  }

  ngOnInit(): void {}

  onSubmitForm(eventArgs): void {
    if (!this.form.valid) {
      return;
    }

    const settings: ConfigurationSettingsDto[] = [];

    settings.push({
      settingName: this.form.get('clientId').get('name').value,
      settingValue: this.form.get('clientId').get('value').value,
    });

    settings.push({
      settingName: this.form.get('clientSecret').get('name').value,
      settingValue: this.form.get('clientSecret').get('value').value,
    });

    settings.push({
      settingName: this.form.get('tenantName').get('name').value,
      settingValue: this.form.get('tenantName').get('value').value,
    });

    settings.push({
      settingName: this.form.get('sourceName').get('name').value,
      settingValue: this.form.get('sourceName').get('value').value,
    });

    settings.push({
      settingName: this.form.get('migrationStartDate').get('name').value,
      settingValue: this.form.get('migrationStartDate').get('value').value,
    });

    settings.push({
      settingName: this.form.get('defaultLanguageCode').get('name').value,
      settingValue: this.form.get('defaultLanguageCode').get('value').value,
    });

    settings.push({
      settingName: this.form.get('languageCodeTags').get('name').value,
      settingValue: this.form.get('languageCodeTags').get('value').value,
    });

    settings.push({
      settingName: this.form.get('useQueueFilter').get('name').value,
      settingValue: this.useQueueFilterCb.toString(),
    });

    settings.push({
      settingName: this.form.get('queueFilterDictionary').get('name').value,
      settingValue: this.form.get('queueFilterDictionary').get('value').value,
    });

    settings.push({
      settingName: this.form.get('useAgentFilter').get('name').value,
      settingValue: this.useAgentFilterCb.toString(),
    });

    settings.push({
      settingName: this.form.get('agentFilterDictionary').get('name').value,
      settingValue: this.form.get('agentFilterDictionary').get('value').value,
    });

    settings.push({
      settingName: this.form.get('useCustomFieldFilter').get('name').value,
      settingValue: this.useCustomFieldFilterCb.toString(),
    });

    settings.push({
      settingName: this.form.get('customFieldFilterDictionary').get('name').value,
      settingValue: this.form.get('customFieldFilterDictionary').get('value').value,
    });

    this.settingService.saveSetting(settings).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SavedSuccessfully')
      );
    });
  }

  onUseQueueFilterChange(value: boolean) {
    console.log('onUseQueueFilterChange triggered');
    if (value) {
      this.form.controls.queueFilterDictionary.setValidators([Validators.required]);
      console.log('queueFilterDictionary setValidators as Validators.required');
    } else {
      this.form.controls.queueFilterDictionary.clearValidators();
      console.log('queueFilterDictionary clearValidators');
    }
    this.form.controls.queueFilterDictionary.updateValueAndValidity();
  }

  onUseAgentFilterChange(value: boolean) {
    console.log('onUseAgentFilterChange triggered');
    if (value) {
      this.form.controls.agentFilterDictionary.setValidators([Validators.required]);
      console.log('agentFilterDictionary setValidators as Validators.required');
    } else {
      this.form.controls.agentFilterDictionary.clearValidators();
      console.log('agentFilterDictionary clearValidators');
    }
    this.form.controls.agentFilterDictionary.updateValueAndValidity();
  }

  onUseCustomFieldFilterChange(value: boolean) {
    console.log('onUseCustomFieldFilterChange triggered');
    if (value) {
      this.form.controls.customFieldFilterDictionary.setValidators([Validators.required]);
      console.log('customFieldFilterDictionary setValidators as Validators.required');
    } else {
      this.form.controls.customFieldFilterDictionary.clearValidators();
      console.log('customFieldFilterDictionary clearValidators');
    }
    this.form.controls.customFieldFilterDictionary.updateValueAndValidity();
  }
}
