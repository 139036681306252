import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ca-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SearchBoxComponent),
    },
  ],
})
export class SearchBoxComponent implements OnInit, ControlValueAccessor {
  searchDebounceTime = 600;

  @Input()
  loaderEnabled: boolean;

  @Input()
  processing: boolean;

  @Input()
  placeholder: string;

  @Input()
  styles: any = '';

  @Input()
  showLoader: boolean;

  @Output()
  searchRequested = new EventEmitter();

  set value(value: string) {
    this._value = value;
  }

  get value(): string {
    return this._value;
  }

  private _value: string;

  source: any;

  private subject: Subject<boolean> = new Subject();

  constructor() {}

  setDisabledState?(isDisabled: boolean): void {
    console.log('setDisabledState method not implemented.');
  }

  writeValue(value: string): void {
    if (value) {
      this.value = value;
    }
  }

  ngOnInit(): void {
    this.subject.pipe(debounceTime(this.searchDebounceTime)).subscribe(cancelRequest => {
      if (!cancelRequest) {
        this.doSearch();
      }
    });
  }

  onFocusQuickSearchInput(eventArgs: { currentTarget: HTMLInputElement }) {
    eventArgs.currentTarget.classList.add('focused');
  }

  onBlurQuickSearchInput(eventArgs: { currentTarget: HTMLInputElement }) {
    if (eventArgs.currentTarget.value.length <= 0) {
      eventArgs.currentTarget.classList.remove('focused');
    }
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  onChange: Function = (_: any) => {};

  onTouched: Function = (_: any) => {};

  onKeyUp(eventArgs: KeyboardEvent) {
    if (eventArgs.key === 'Enter') {
      this.subject.next(true);
      this.doSearch();
    } else if (this.value.length != 1) {
      this.subject.next(false);
    }
  }

  doSearch() {
    if (this.value.length === 1) {
      return;
    }

    if (this.loaderEnabled) {
      this.processing = true;
    }

    this.onChange(this.value);
    this.searchRequested.emit();
  }
}
