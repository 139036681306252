import {
  ConfigStateService,
  CoreModule,
  LocalizationService,
  provideAbpCore,
  withOptions,
} from '@abp/ng.core';
import { provideSettingManagementConfig } from '@abp/ng.setting-management/config';
import {
  provideAbpThemeShared,
  ThemeSharedModule,
  ToasterService,
  withValidationBluePrint,
} from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { provideCommercialUiConfig } from '@volo/abp.commercial.ng.ui/config';
import { provideAccountAdminConfig } from '@volo/abp.ng.account/admin/config';
import { provideAccountPublicConfig } from '@volo/abp.ng.account/public/config';
import { provideAuditLoggingConfig } from '@volo/abp.ng.audit-logging/config';
import { provideOpeniddictproConfig } from '@volo/abp.ng.openiddictpro/config';
import { provideIdentityConfig } from '@volo/abp.ng.identity/config';
import { provideLanguageManagementConfig } from '@volo/abp.ng.language-management/config';
import { provideTextTemplateManagementConfig } from '@volo/abp.ng.text-template-management/config';
import { registerLocale } from '@volo/abp.ng.language-management/locale';
import { SaasConfigModule } from '@volo/abp.ng.saas/config';
import {
  HttpErrorComponent,
  provideThemeLepton,
  ValidationErrorComponent,
} from '@volo/abp.ng.theme.lepton';
import { UserPhotoModule } from 'src/ca-shared/user-photo/user-photo.module';
import { EnumResolver } from 'src/core/resolvers/enum.resolver';
import { GenericLookupTypeResolver } from 'src/core/resolvers/generic-lookup-type.resolver';
import { CARouteReuseStrategy } from 'src/core/routing/ca-router-reuse-strategy';
import { AdministrationModuleState } from 'src/core/states/administration/administration-module.state';
import { TrendAnalysisState } from 'src/core/states/analysis/trend-analysis.state';
import { FilterBreadcurmbState } from 'src/core/states/filter-breadcrumb/filter-breadcrumb.state';
import { ConversationModuleState } from 'src/core/states/conversation/conversation-module.state';
import { DashboardModuleState } from 'src/core/states/dashboard/dashboard-module.state';
import { EnumState } from 'src/core/states/enum/enum.state';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { CALayoutState } from 'src/core/states/layout/ca-layout.state';
import { environment } from '../environments/environment';
import { IndexingSettingsComponent } from './administration/settings/indexing-settings/indexing-settings.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConversationModule } from './conversation/conversation.module';
import { ReplacementModule } from './replacement/replacement.module';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSplitModule } from 'angular-split';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { MatIconModule } from '@angular/material/icon';
import { DatePipe, DecimalPipe } from '@angular/common';
import {
  HttpClient,
  HTTP_INTERCEPTORS,
  withXsrfConfiguration,
  provideHttpClient,
} from '@angular/common/http';
import { HttpErrorResponseInterceptor } from 'src/core/interceptors/http-error-response.interceptor';
import { RouteReuseStrategy } from '@angular/router';
import { NonFcrState } from 'src/core/states/analysis/non-fcr.state';
import { UserGroupModule } from './user-group/user-group.module';
import { StatisticComparisonState } from 'src/core/states/analysis/statistic-comparison.state';
import { FilterPanelState } from 'src/ca-shared/filter-panel/filter-panel.module';
import { GenericState } from 'src/core/states/generic/generic.state';
import { CustomMatPaginatorIntl } from 'src/core/localizations/paginator/CustomMatPaginatorIntl';
import { CanDeactivateGuard } from 'src/core/guards/can-deactivate.guard';
import { RecorderService } from 'src/core/services/conversation/recorder.service';
import { ToastrModule } from 'ngx-toastr';
import { CAToasterService } from 'src/core/services/toaster/ca-toaster.service';
import { EvaluationResultState } from 'src/core/states/quality/evaluation-result.state';
import { SharedModule } from './shared/shared.module';
import { NotificationModule } from './notification/notification.module';
import { UserFeedbackComponent } from './user-feedback/user-feedback/user-feedback.component';
import { ExternalModule } from './external/external.module';
import { InitialSetupComponent } from './initial-setup/initial-setup/initial-setup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FooterComponent } from './footer/footer/footer.component';
import { IdleTimeoutModalComponent } from './idle-timeout/idle-timeout-modal/idle-timeout-modal.component';
import { NotificationState } from 'src/core/states/notification/notification.state';
import { QualityDashboardModuleState } from 'src/core/states/quality/quality-dashboard-module.state';
import { AgentPerformanceModuleState } from 'src/core/states/quality/agent-performance-module.state';
import { CaSharedModule } from 'src/ca-shared/ca-shared.module';
import { VideoPlayerState } from 'src/core/states/video-player/video-player.state';
import { CDK_DRAG_CONFIG, DragDropModule } from '@angular/cdk/drag-drop';
import { BlockUIModule } from 'ng-block-ui';
import { PremiumReportingConfigModule } from '@sestek/premium-reporting/config';
import { ConversationMarkState } from 'src/core/states/conversation/conversation-mark.state';
import { ConversationDetailState } from 'src/core/states/conversation/conversation-detail.state';
import { UserStorageEngine } from 'src/core/states/user-storage-engine';
import { SupervisorDashboardState } from 'src/core/states/real-time/supervisor-dashboard.state';
import { ColumnConfiguratorModule } from 'src/ui/column-configurator/column-configurator.module';
import { ColumnConfiguratorModuleState } from 'src/ui/column-configurator/state/column-configurator-module.state';
import { UserLoginState } from 'src/core/states/administration/user-login.state';
import { UserStateResolver } from 'src/core/resolvers/user-state.resolver';
import { provideAbpOAuth } from '@abp/ng.oauth';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { VALIDATION_BLUEPRINTS, VALIDATION_ERROR_TEMPLATE } from '@ngx-validate/core';
import { DEFAULT_VALIDATION_BLUEPRINTS } from '@abp/ng.theme.shared';
import { ErrorComponent } from './replacement/error.component';
import {
  VALIDATION_MESSAGE_PROVIDER,
  ValidationMessageService,
} from 'src/core/services/validation/validation-message.service';

@NgModule({
  declarations: [
    AppComponent,
    IndexingSettingsComponent,
    UserFeedbackComponent,
    InitialSetupComponent,
    FooterComponent,
    IdleTimeoutModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    ThemeSharedModule,
    NgSelectModule,
    DragDropModule,
    NotificationModule,
    SharedModule,
    CaSharedModule,
    SaasConfigModule.forRoot(),
    AngularSplitModule,
    BlockUIModule.forRoot(),
    NgSelectModule,
    ReplacementModule,
    ConversationModule,
    UserGroupModule,
    UserPhotoModule,
    ExternalModule,
    ToastrModule.forRoot(),
    NgxsModule.forRoot([
      ConversationModuleState,
      GenericLookupTypeState,
      AdministrationModuleState,
      CALayoutState,
      EnumState,
      ConversationMarkState,
      GenericState,
      DashboardModuleState,
      NonFcrState,
      EvaluationResultState,
      TrendAnalysisState,
      FilterBreadcurmbState,
      StatisticComparisonState,
      FilterPanelState,
      NotificationState,
      VideoPlayerState,
      AgentPerformanceModuleState,
      QualityDashboardModuleState,
      ConversationDetailState,
      SupervisorDashboardState,
      UserLoginState,
    ]),
    NgbModule,
    NgxsResetPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [
        ConversationModuleState,
        CALayoutState,
        ConversationMarkState,
        DashboardModuleState,
        NonFcrState,
        EvaluationResultState,
        TrendAnalysisState,
        FilterBreadcurmbState,
        StatisticComparisonState,
        FilterPanelState,
        NotificationState,
        AgentPerformanceModuleState,
        QualityDashboardModuleState,
        VideoPlayerState,
        SupervisorDashboardState,
        ColumnConfiguratorModuleState,
      ],
    }),
    MatSlideToggleModule,
    MatIconModule,
    ColumnConfiguratorModule,
    PremiumReportingConfigModule.forRoot(),
  ],
  providers: [
    GenericLookupTypeResolver,
    provideThemeLepton(),
    provideAbpOAuth(),
    provideOpeniddictproConfig(),
    provideTextTemplateManagementConfig(),
    provideLanguageManagementConfig(),
    provideAccountPublicConfig(),
    provideCommercialUiConfig(),
    provideSettingManagementConfig(),
    provideAuditLoggingConfig(),
    provideIdentityConfig(),
    provideHttpClient(
      withXsrfConfiguration({ cookieName: 'XSRF-TOKEN', headerName: 'Requestverificationtoken' })
    ),
    provideAccountAdminConfig(),
    provideAbpCore(
      withOptions({
        environment,
        registerLocaleFn: registerLocale(),
      })
    ),
    provideAbpThemeShared(),
    EnumResolver,
    UserStateResolver,
    DatePipe,
    CanDeactivateGuard,
    {
      provide: ToasterService,
      useClass: CAToasterService,
    },
    {
      provide: VALIDATION_ERROR_TEMPLATE,
      useValue: ValidationErrorComponent,
    },
    VALIDATION_MESSAGE_PROVIDER,
    DecimalPipe,
    {
      provide: RecorderService,
      deps: [HttpClient, ConfigStateService],
      useFactory(
        httpClient: HttpClient,
        configStateService: ConfigStateService,
        localizationService: LocalizationService
      ) {
        return new RecorderService(httpClient, configStateService, localizationService);
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorResponseInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CARouteReuseStrategy,
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
    APP_ROUTE_PROVIDER,
    {
      provide: STORAGE_ENGINE,
      useClass: UserStorageEngine,
    },
    {
      provide: CDK_DRAG_CONFIG,
      useValue: {
        zIndex: 10000,
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
