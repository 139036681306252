import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { IntegerValidator } from 'src/core/validators/shared/integer.validator';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'ca-real-time-analysis-settings',
  templateUrl: './real-time-analysis-settings.component.html',
  styleUrls: ['./real-time-analysis-settings.component.scss'],
})
export class RealTimeAnalysisSettingsComponent implements OnInit {
  realTimeSettingsForm: FormGroup;
  realTimeSettingDtos: ConfigurationSettingsDto[] = [];

  private readonly settingKeyMinSpeechRate = 'Analysis.Scenario.MinSpeechRate';
  private readonly settingKeyMaxSpeechRate = 'Analysis.Scenario.MaxSpeechRate';
  private readonly settingKeyMinMonotonicity = 'Analysis.Scenario.MinMonotonicity';
  private readonly settingKeyMaxMonotonicity = 'Analysis.Scenario.MaxMonotonicity';

  private addScenarioSettings() {
    this.realTimeSettingDtos.push(
      {
        settingName: this.settingKeyMinSpeechRate,
        settingValue: this.realTimeSettingsForm
          .get('scenarioSettingsForm.minSpeechRate')
          .value.toString(),
      },
      {
        settingName: this.settingKeyMaxSpeechRate,
        settingValue: this.realTimeSettingsForm
          .get('scenarioSettingsForm.maxSpeechRate')
          .value.toString(),
      },
      {
        settingName: this.settingKeyMinMonotonicity,
        settingValue: this.realTimeSettingsForm
          .get('scenarioSettingsForm.minMonotonicity')
          .value.toString(),
      },
      {
        settingName: this.settingKeyMaxMonotonicity,
        settingValue: this.realTimeSettingsForm
          .get('scenarioSettingsForm.maxMonotonicity')
          .value.toString(),
      }
    );
  }

  onSubmitAnalysisSettings() {
    if (this.realTimeSettingsForm.invalid) {
      return;
    }

    this.addScenarioSettings();

    this.settingService.saveSetting(this.realTimeSettingDtos).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SavedSuccessfully')
      );
    });
  }

  popoverClicked(popover: NgbPopover, event: any): boolean {
    popover.toggle();
    event.stopPropagation();
    return false;
  }

  constructor(
    private config: ConfigStateService,
    private fb: FormBuilder,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private featureService: FeatureService
  ) {
    this.realTimeSettingsForm = fb.group({
      scenarioSettingsForm: fb.group({
        minSpeechRate: [
          this.config.getSetting(this.settingKeyMinSpeechRate),
          { validators: [Validators.required, IntegerValidator.minMax(0, null)] },
        ],
        maxSpeechRate: [
          this.config.getSetting(this.settingKeyMaxSpeechRate),
          { validators: [Validators.required, IntegerValidator.minMax(0, null)] },
        ],
        minMonotonicity: [
          this.config.getSetting(this.settingKeyMinMonotonicity),
          { validators: [Validators.required, IntegerValidator.minMax(0, 100)] },
        ],
        maxMonotonicity: [
          this.config.getSetting(this.settingKeyMaxMonotonicity),
          { validators: [Validators.required, IntegerValidator.minMax(0, 100)] },
        ],
      }),
    });
  }

  ngOnInit(): void {}
}
