import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, shareReplay } from 'rxjs/operators';
import { DataChange } from '../actions/enum/enum.actions';

@Injectable()
export class EnumResolver  {
  private cache$: Observable<any>;
  constructor(private http: HttpClient, private store: Store) {}

  private getUrl(): string {
    const apiBase = environment.apis.default.url;

    return apiBase.length > 0 ? apiBase + '/api/app/enum' : 'api/app/enum';
  }

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const url = this.getUrl();

    let req = this.http.get(url).pipe(
      map(r => {
        const action = new DataChange(r);
        this.store.dispatch(action);

        return true;
      })
    );
    if (!this.cache$) {
      this.cache$ = req.pipe(shareReplay(1));
    }

    return this.cache$;
  }
}
