import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaSharedModule } from 'src/ca-shared/ca-shared.module';
import { SharedModule } from '../shared/shared.module';
import { SelectorModule } from 'src/ca-shared/selector/selector.module';
import { CoreModule } from 'src/core/core.module';
import { NgbModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { UserGroupListToolbarComponent } from './user-group/user-group-list-toolbar/user-group-list-toolbar.component';
import { UserGroupListComponent } from './user-group/user-group-list/user-group-list.component';
import { UserGroupFormComponent } from './user-group/user-group-form/user-group-form.component';
import { UserGroupMainComponent } from './user-group/user-group-main/user-group-main.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { AngularSplitModule } from 'angular-split';
import { InteractionManagementListToolbarComponent } from './interaction-management/interaction-management-list-toolbar/interaction-management-list-toolbar.component';
import { InteractionManagementListComponent } from './interaction-management/interaction-management-list/interaction-management-list.component';
import { InteractionManagementFormComponent } from './interaction-management/interaction-management-form/interaction-management-form.component';
import { InteractionManagementMainComponent } from './interaction-management/interaction-management-main/interaction-management-main.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [
    UserGroupListToolbarComponent,
    UserGroupListComponent,
    UserGroupFormComponent,
    UserGroupMainComponent,
    InteractionManagementListToolbarComponent,
    InteractionManagementListComponent,
    InteractionManagementFormComponent,
    InteractionManagementMainComponent,
  ],
  imports: [
    CaSharedModule,
    CommonModule,
    SharedModule,
    AngularSplitModule,
    MatTooltipModule,
    MatIconModule,
    NgbModalModule,
    SelectorModule,
    CoreModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatTableModule,
    MatPaginatorModule,
    NgbModule,
  ],
})
export class UserGroupModule {}
