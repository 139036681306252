<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="sourceName">
    <label for="sourceName">{{ 'Settings::NiceIntegrationSourceName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="sourceName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientId">
    <label for="clientId">{{ 'Settings::NiceIntegrationClientId' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="clientId"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientSecret">
    <label for="clientSecret">{{ 'Settings::NiceIntegrationClientSecret' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="clientSecret"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="accessKey">
    <label for="accessKey">{{ 'Settings::NiceIntegrationAccessKey' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="accessKey"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="accessSecret">
    <label for="accessSecret">{{ 'Settings::NiceIntegrationAccessSecret' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="accessSecret"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="migrationStartDate">
    <label for="migrationStartDate">{{ 'Settings::NiceIntegrationMigrationStartDate' | abpLocalization }}</label>
    <input
      type="text"
      placeholder="2023-01-31"
      class="form-control form-control-sm"
      id="migrationStartDate"
      formControlName="value" />
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
