import { LocalizationService } from '@abp/ng.core';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableColumnDefinitionModel } from 'src/core/models/mat-table/mat-table-column-definition.model';
import { ReasonDto } from 'src/core/models/quality/reason.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { CrudService } from 'src/core/services/crud/crud.service';

@Component({
  selector: 'ca-reason-table-selector',
  templateUrl: './reason-table-selector.component.html',
  styleUrls: ['./reason-table-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReasonTableSelectorComponent),
    },
  ],
})
export class ReasonTableSelectorComponent implements OnInit, ControlValueAccessor {
  @Input()
  reasonType: number;

  @Input()
  conversationId: number;

  selectedReasonList: ReasonDto[] = [];
  isDisabled: boolean = false;

  dataSource = new MatTableDataSource<ReasonDto>();
  data: ReasonDto[] = [];
  displayedColumns: string[] = [];

  totalCount = 0;
  skipCount = 0;
  pageSize = 30;

  filters: FilterItemDto[] = [];
  sorters: SorterItemDto[] = [];

  get value(): ReasonDto[] {
    return this.selectedReasonList;
  }

  gridColumns: MatTableColumnDefinitionModel[] = [
    {
      columnName: 'menu',
      header: '',
      binding: 'id',
      valueParser: undefined,
      pipes: undefined,
      width: '100px',
      tooltip: 'Menu',
    },
    {
      columnName: 'text',
      header: this.localizationService.instant('QualityManagement::Reason'),
      binding: 'text',
      valueParser: undefined,
      pipes: undefined,
      width: '100%',
      tooltip: undefined,
    },
  ];

  constructor(
    private service: CrudService,
    private localizationService: LocalizationService,
    private operators: Operators
  ) {
    for (const gridColumn of this.gridColumns) {
      this.displayedColumns.push(gridColumn.columnName);
    }
  }

  writeValue(obj: any): void {
    obj = obj ? obj : [];
    this.selectedReasonList = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange(selection: any[]) {}

  onTouched() {}
  ngOnInit(): void {
    this.filters.push({
      field: 'reasonType',
      operator: this.operators.Equals,
      value: this.reasonType,
    });
    this.sorters.push({
      field: 'Text',
      direction: 'ASC',
    });
    this.load(this.filters, this.sorters);
  }

  setDisabledState(isDisabled) {
    this.isDisabled = isDisabled;
  }

  load(filters: FilterItemDto[], sorters: SorterItemDto[]) {
    this.service
      .get<ReasonDto>(ReasonDto, {
        maxResultCount: this.pageSize,
        skipCount: this.skipCount,
        filters: filters,
        sorters: sorters,
      })
      .subscribe(response => {
        this.data = response.items;
        this.totalCount = response.totalCount;
        this.dataSource.connect().next(this.data);
      });
  }

  selectReason(eventArgs, reason: ReasonDto) {
    var element = <HTMLInputElement>document.getElementById(reason.id + '');
    if (!this.selectedReasonList.includes(reason)) {
      element.checked = true;
      this.selectedReasonList.push(reason);
    } else {
      element.checked = false;
      this.selectedReasonList = this.selectedReasonList.filter(f => f.id != reason.id);
    }
    this.onChange(this.value);
  }
}
