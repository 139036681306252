<div [ngClass]="'d-flex ' + divClass">
  <div class="mt-auto mb-auto">
    <ca-user-photo
      [classes]="['profile-photo', 'me-2']"
      [useCurrentUser]="false"
      [userId]="id"
      [nameAndSurname]="[name, surname]"></ca-user-photo>
    <ng-content select="icon-container"></ng-content>
  </div>
  <div
    *ngIf="!itemTemplate"
    class="mt-auto mb-auto truncate-text"
    [ngbTooltip]="name"
    showTooltipIfTruncated
    container="body">
    {{ name }}
  </div>
  <ng-container *ngIf="itemTemplate">
    <ng-container *ngTemplateOutlet="itemTemplate; context: { item: item }"></ng-container>
  </ng-container>
</div>
