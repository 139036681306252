import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { take } from 'rxjs';
import { NERTemplateDto } from 'src/core/models/conversation/phrase-type/ner-template.dto';
import { PhraseTypeSettingsService } from 'src/core/services/settings/phrase-type-settings.service';

@Component({
  selector: 'ca-named-entity-recognition-settings',
  templateUrl: './named-entity-recognition-settings.component.html',
  styleUrls: ['./named-entity-recognition-settings.component.scss'],
})
export class NamedEntityRecognitionSettingsComponent implements OnInit {
  form: FormGroup;
  templateList: NERTemplateDto[] = [];

  constructor(
    private fb: FormBuilder,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private phraseTypeSettingsService: PhraseTypeSettingsService,
    private config: ConfigStateService
  ) {
    this.form = fb.group({
      templates: [],
    });
    this.phraseTypeSettingsService
      .getNERTemplates()
      .pipe(take(1))
      .subscribe({
        next: response => {
          this.templateList = response;
          this.templateList = this.templateList.map(p =>
            p.isUsedInRedaction ? { ...p, disabled: true } : p
          );
          const enabledNERTemplatesIds = this.templateList.filter(x => x.isEnabled).map(t => t.id);
          this.form.get('templates').setValue(enabledNERTemplatesIds);
        },
        error: () => {},
      });
  }

  ngOnInit(): void {}

  onSubmitForm() {
    if (!this.form.valid) {
      return;
    }

    let templates = this.form.get('templates').value ?? [];
    this.phraseTypeSettingsService.updateNEREnability(templates).subscribe(response => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SavedSuccessfully')
      );
    });
  }

  removeFromSelected(id) {
    let selectedItems = this.form.get('templates').value ?? [];
    if (selectedItems) {
      const indexToRemoveFromSelected = selectedItems.findIndex(idx => idx === id);
      if (indexToRemoveFromSelected > -1) {
        selectedItems.splice(indexToRemoveFromSelected, 1);
        this.form.get('templates').setValue(selectedItems);
      }
    }
  }
}
