<ng-select
  *ngIf="this.multiple"
  #ngSelect
  class="department-selector"
  appendTo="body"
  (change)="onChangeBase($event)"
  (open)="onOpen()"
  (add)="onAdd($event)"
  (clear)="onClear($event)"
  [placeholder]="placeholder"
  [multiple]="multiple"
  [closeOnSelect]="closeOnSelect"
  [searchable]="true"
  [loading]="loading"
  [(ngModel)]="multipleValue"
  [compareWith]="compareFn"
  [ngClass]="classList"
  [disabled]="isDisabled"
  [typeahead]="quickSearchTermInput$">
  <ng-template ng-label-tmp let-node="item" class="truncate-text">
    {{ node.department.name }}
  </ng-template>
  <ng-template
    *ngIf="multipleSingleRemove"
    ng-multi-label-tmp
    let-items="items"
    let-clear="clear"
    class="truncate-text">
    <div class="ng-value" *ngFor="let item of items; let i = index">
      <span
        class="ng-value-icon left"
        aria-hidden="true"
        (click)="removeFromSelectedDepartment(item)">
        ×
      </span>
      <span class="ng-value-label">
        <i
          *ngIf="!hideHeader"
          class="me-2 fa-solid fa-circle"
          [ngClass]="item.department.isActive ? 'circle-active' : 'circle-passive'"></i>
        <span class="ms-2 truncate-text" [ngbTooltip]="item.department.name">
          {{ item.department.name }}
        </span>
      </span>
    </div>
  </ng-template>
  <ng-template *ngIf="!multipleSingleRemove" ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngIf="items.length === 1">
      <span class="ng-value-label d-flex flex-row justify-content-start align-items-center">
        <i
          *ngIf="!hideHeader"
          class="me-2 fa-solid fa-circle"
          [ngClass]="items[0].department.isActive ? 'circle-active' : 'circle-passive'"></i>
        <span class="ms-2 truncate-text" [ngbTooltip]="items[0].department.name">
          {{ items[0].department.name }}
        </span>
      </span>
    </div>
    <div class="ng-value" *ngIf="items.length > 1">
      <span class="ng-value-label">
        {{ 'CA::SelectedDepartmentsWithCount' | abpLocalization : items.length }}...
      </span>
    </div>
  </ng-template>
  <ng-template ng-option-tmp let-node="item" let-index="index" let-search="searchTerm">
    <div
      [title]="node.department.name"
      class="option-div d-flex flex-row align-items-center"
      [style.marginLeft]="node.level * 16 + 'px'">
      <div
        *ngIf="node.department.hasChildren"
        class="me-2 mt-1 mb-1"
        (click)="
          node.expanded
            ? onCollapseDepartmentClick($event, node, index)
            : onExpandDepartmentClick($event, node, index)
        ">
        <i
          *ngIf="!node.expanded && node.department.hasChildren"
          class="fa-sharp fa-solid fa-caret-right fa-lg"></i>
        <i
          *ngIf="node.expanded && node.department.hasChildren"
          class="fa-sharp fa-solid fa-caret-down fa-lg"></i>
      </div>
      <div class="me-2 mt-1 mb-1" *ngIf="!node.department.hasChildren">
        <i class="fa-sharp fa-solid fa-caret-down fa-lg opacity-25"></i>
      </div>
      <input
        *ngIf="multiple"
        type="checkbox"
        class="ms-2"
        [style.marginBottom]="'2px'"
        (click)="onDepartmentCheckClick($event, node)"
        [checked]="isDepartmentSelected(node.department.id)" />
      <i
        *ngIf="!hideHeader"
        class="ms-2 fa-solid fa-circle"
        [ngClass]="node.department.isActive ? 'circle-active' : 'circle-passive'"></i>
      <span class="ms-2 truncate-text" [ngbTooltip]="node.department.name">
        {{ node.department.name }}
      </span>
    </div>
  </ng-template>
  <ng-template ng-header-tmp *ngIf="!hideHeader">
    <div class="d-flex flex-row align-items-center justify-content-start pt-1 pb-1">
      <div [attr.title]="'AbpUi::DepartmentSelector:AllRadioTooltip' | abpLocalization">
        <input
          id="rdAll"
          name="activityFilter"
          class="form-check-input"
          value="all"
          type="radio"
          (change)="onStatusFilterChanged($event)"
          [checked]="departmentStatusFilter === 'all'" />
        <label for="rdAll" class="form-check-label ms-2">
          <i class="fa-solid fa-circle-half circle-active"></i>
          <i class="fa-solid fa-circle-half fa-flip-horizontal circle-passive circle-stacked"></i>
          <span class="ms-2">{{ 'CA::All' | abpLocalization }}</span>
        </label>
      </div>
      <div
        class="ms-4"
        [attr.title]="'AbpUi::DepartmentSelector:ActiveRadioTooltip' | abpLocalization">
        <input
          id="rdActive"
          name="activityFilter"
          class="form-check-input"
          value="active"
          type="radio"
          (change)="onStatusFilterChanged($event)"
          [checked]="departmentStatusFilter === 'active'" />
        <label for="rdActive" class="form-check-label ms-2">
          <i class="fa-solid fa-circle circle-active"></i>
          <span class="ms-2">{{ 'CA::Active' | abpLocalization }}</span>
        </label>
      </div>
      <div class="ms-4"></div>
    </div>
  </ng-template>
</ng-select>

<ng-select
  *ngIf="!this.multiple"
  #ngSelect
  class="department-selector"
  appendTo="body"
  (change)="onChangeBase($event)"
  (open)="onOpen()"
  (add)="onAdd($event)"
  (clear)="onClear($event)"
  [placeholder]="placeholder"
  [multiple]="multiple"
  [closeOnSelect]="closeOnSelect"
  [searchable]="true"
  [loading]="loading"
  [(ngModel)]="singleValue"
  [compareWith]="compareFn"
  [ngClass]="classList"
  [disabled]="isDisabled"
  [typeahead]="quickSearchTermInput$">
  <ng-template ng-label-tmp let-node="item" class="truncate-text">
    {{ node.department.name }}
  </ng-template>
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngIf="items.length === 1">
      <span class="ng-value-label d-flex flex-row justify-content-start align-items-center">
        <i
          class="me-2 fa-solid fa-circle"
          [ngClass]="items[0].department.isActive ? 'circle-active' : 'circle-passive'"></i>
        <span class="ms-2 truncate-text" [ngbTooltip]="items[0].department.name">
          {{ items[0].department.name }}
        </span>
      </span>
    </div>
    <div class="ng-value" *ngIf="items.length > 1">
      <span class="ng-value-label">
        {{ 'CA::SelectedDepartmentsWithCount' | abpLocalization : items.length }}...
      </span>
    </div>
  </ng-template>
  <ng-template ng-option-tmp let-node="item" let-index="index" let-search="searchTerm">
    <div
      [title]="node.department.name"
      class="option-div d-flex flex-row align-items-center"
      [style.marginLeft]="node.level * 16 + 'px'">
      <div
        *ngIf="node.department.hasChildren"
        class="me-2 mt-1 mb-1"
        (click)="
          node.expanded
            ? onCollapseDepartmentClick($event, node, index)
            : onExpandDepartmentClick($event, node, index)
        ">
        <i
          *ngIf="!node.expanded && node.department.hasChildren"
          class="fa-sharp fa-solid fa-caret-right fa-lg"></i>
        <i
          *ngIf="node.expanded && node.department.hasChildren"
          class="fa-sharp fa-solid fa-caret-down fa-lg"></i>
      </div>
      <div class="me-2 mt-1 mb-1" *ngIf="!node.department.hasChildren">
        <i class="fa-sharp fa-solid fa-caret-down fa-lg opacity-25"></i>
      </div>
      <input
        *ngIf="multiple"
        type="checkbox"
        class="ms-2"
        [style.marginBottom]="'2px'"
        (click)="onDepartmentCheckClick($event, node)"
        [checked]="isDepartmentSelected(node.department.id)" />
      <i
        class="ms-2 fa-solid fa-circle"
        [ngClass]="node.department.isActive ? 'circle-active' : 'circle-passive'"></i>
      <span class="ms-2 truncate-text" [ngbTooltip]="node.department.name">
        {{ node.department.name }}
      </span>
    </div>
  </ng-template>
  <ng-template ng-header-tmp>
    <div class="d-flex flex-row align-items-center justify-content-start pt-1 pb-1">
      <div [attr.title]="'AbpUi::DepartmentSelector:AllRadioTooltip' | abpLocalization">
        <input
          id="rdAll"
          name="activityFilter"
          class="form-check-input"
          value="all"
          type="radio"
          (change)="onStatusFilterChanged($event)"
          [checked]="departmentStatusFilter === 'all'" />
        <label for="rdAll" class="form-check-label ms-2">
          <i class="fa-solid fa-circle-half circle-active"></i>
          <i class="fa-solid fa-circle-half fa-flip-horizontal circle-passive circle-stacked"></i>
          <span class="ms-2">{{ 'CA::All' | abpLocalization }}</span>
        </label>
      </div>
      <div
        class="ms-4"
        [attr.title]="'AbpUi::DepartmentSelector:ActiveRadioTooltip' | abpLocalization">
        <input
          id="rdActive"
          name="activityFilter"
          class="form-check-input"
          value="active"
          type="radio"
          (change)="onStatusFilterChanged($event)"
          [checked]="departmentStatusFilter === 'active'" />
        <label for="rdActive" class="form-check-label ms-2">
          <i class="fa-solid fa-circle circle-active"></i>
          <span class="ms-2">{{ 'CA::Active' | abpLocalization }}</span>
        </label>
      </div>
      <div class="ms-4"></div>
    </div>
  </ng-template>
</ng-select>
